import React from "react";
import moment from "moment";

import "./index.scss";

const Activity = props => {
  const { username, createdAt, description } = props;
  return (
    <div className="activity">
      <p className="activity-description">{description.toUpperCase()} </p>
      <p className="date-and-time">
        {moment
          .utc(createdAt)
          .local()
          .format("DD MMM, YYYY")}
      </p>
      <p className="date-and-time">
        {moment
          .utc(createdAt)
          .local()
          .format("kk:mm")}
      </p>
      <p className="user">{`user: ${username}`} </p>
      <div className="horizont-line" />
    </div>
  );
};
export default Activity;
