import React, { useMemo, useEffect } from "react";

import InputImg from "../../common/InputImg";
import InputVideo from "../../common/InputVideo";
import Textarea from "../../common/Textarea";
import Switcher from "../../Switcher";
import Radiobutton from "../../common/Radiobutton";
import AccordionItem from "../../Accordion/AccordionItem";
import Select from "../../common/Select";

import "./index.scss";

const PhotoInputs = props => {
  const {
    setContent,
    content,
    pages,
    tags,
    pageEnabled = true,
    notificationEnabled = true,
    video = false
  } = props;

  const setBlurZero = () => {
    setContent({
      ...content,
      premium_blur: 0
    });
  };

  useEffect(() => {
    if (content.premium_blur > 60) {
      setBlurZero();
    }
  }, [content.premium_blur]);

  const checkIsEmptyBlur = () => {
    if (!content.premium_blur) {
      setBlurZero();
    }
  };

  const configurationsArray = useMemo(() => {
    const configurations = [
      { title: "Likes", value: "likes_enabled" },
      { title: "Premium", value: "is_premium" },
      { title: "Comments", value: "comments_enabled" },
      { title: "Share", value: "shares_enabled" }
    ];
    if (video)
      configurations.push({
        title: "Premium Blur",
        value: "premium_blur",
        is_input: true
      });
    if (notificationEnabled)
      configurations.push({
        title: "Notifications",
        value: "send_notification"
      });
    return configurations;
  }, [video]);

  if (!pageEnabled && !content.main_feed)
    setContent({ ...content, main_feed: true });

  return (
    <div className="photo-inputs-container">
      <div className="add-content-container">
        <p className="title">ADD CONTENT</p>
        <div className="inputs">
          {video ? (
            <InputVideo
              name="photoInput"
              withoutName
              styles={{ width: "350px", height: "374px" }}
              onChange={result => {
                setContent({ ...content, link: result });
              }}
              value={content.preview_link}
            />
          ) : (
            <InputImg
              name="photoInput"
              withoutName
              styles={{ width: "350px", height: "374px" }}
              onChange={result => {
                setContent({ ...content, link: result });
              }}
              withRequirement
              value={content.link}
            />
          )}
          <div className="description-container">
            <div className="description-item">
              <p className="description">Write a description:</p>
              <Textarea
                onChange={event => {
                  setContent({
                    ...content,
                    content_description: event.target.value
                  });
                }}
                value={content.content_description || ""}
              />
            </div>
            <div className="description-item">
              <p className="description">Tags:</p>
              <Select
                isSearchable
                label="Add tags"
                isMulti
                options={tags.general}
                value={
                  content.tags
                    ? tags.general.filter(
                        tag => content.tags.indexOf(tag.value) !== -1
                      )
                    : []
                }
                onChange={items =>
                  setContent({
                    ...content,
                    tags: items.map(item => item.value)
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
      {pageEnabled && (
        <div className="share-container">
          <div>
            <p className="title">WHERE TO SHARE</p>
            <div className="content-feed">
              <span>Content feed</span>
              <Switcher
                onChange={event =>
                  setContent({ ...content, main_feed: event.target.checked })
                }
                checked={content.main_feed}
              />
            </div>
            <form className="share-radiobuttons">
              {pages.map(page => (
                <Radiobutton
                  key={page.id}
                  name="share-type"
                  value={page.id}
                  text={page.name}
                  onChange={event => {
                    setContent({
                      ...content,
                      page: parseInt(event.target.value, 10)
                    });
                  }}
                  checked={content.page === page.id}
                />
              ))}
            </form>
          </div>
        </div>
      )}
      <div className="settings">
        <AccordionItem
          title="SETTINGS"
          hint=""
          configurations={configurationsArray}
          isContentModule={false}
          onEmptyPremiumBlur={checkIsEmptyBlur}
          onChangeBlur={data =>
            setContent({
              ...content,
              [data.value]: data.event.target.value
            })
          }
          onChange={data =>
            setContent({
              ...content,
              [data.value]: data.event.target.checked
            })
          }
          onChangeNotification={data =>
            setContent({
              ...content,
              [data.value]: data.event.target.value
            })
          }
          defaultOpen
          isContent
          content={content}
          isNotificationsOn={content.send_notification}
          checked={item =>
            content[item.value] !== undefined && content[item.value]
          }
        />
      </div>
    </div>
  );
};

export default PhotoInputs;
