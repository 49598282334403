import React from "react";

import InputImg from "../../common/InputImg";
import Input from "../../common/Input";
import Switcher from "../../Switcher";
import Radiobutton from "../../common/Radiobutton";
import AccordionItem from "../../Accordion/AccordionItem";
import Select from "../../common/Select";

import "./index.scss";

const NewsInputs = props => {
  const {
    setContent,
    content,
    pages,
    tags,
    pageEnabled = true,
    notificationEnabled = true
  } = props;

  const configurations = [
    { title: "Likes", value: "likes_enabled" },
    { title: "Premium", value: "is_premium" },
    { title: "Comments", value: "comments_enabled" },
    { title: "Share", value: "shares_enabled" }
  ];
  const inputsValue = [
    { text: "Title:", dbName: "button_text" },
    { text: "Short Description:", dbName: "merchandise_caption" },
    { text: "Link:", dbName: "price" }
  ];

  if (!pageEnabled && !content.main_feed)
    setContent({ ...content, main_feed: true });

  if (notificationEnabled)
    configurations.push({ title: "Notifications", value: "send_notification" });

  return (
    <div className="article-inputs-container">
      <div className="add-content-container">
        <p className="title">ADD CONTENT</p>
        <div className="inputs">
          <InputImg
            name="photoInput"
            withoutName
            styles={{ width: "350px", height: "374px" }}
            onChange={result => {
              setContent({ ...content, link: result });
            }}
            value={content.link}
            withRequirement
          />
          <div className="description-container">
            {inputsValue.map(inputValue => (
              <div key={inputValue.dbName} className="description-item">
                <p className="description">{inputValue.text}</p>
                <Input
                  onChange={event => {
                    setContent({
                      ...content,
                      [inputValue.dbName]: event.target.value
                    });
                  }}
                  value={
                    content[inputValue.dbName] ? content[inputValue.dbName] : ""
                  }
                  type="text"
                />
              </div>
            ))}
            <div className="description-item">
              <p className="description">Tags:</p>
              <Select
                isSearchable
                label="Add tags"
                isMulti
                options={tags.general}
                value={
                  content.tags
                    ? tags.general.filter(
                        tag => content.tags.indexOf(tag.value) !== -1
                      )
                    : []
                }
                onChange={items =>
                  setContent({
                    ...content,
                    tags: items.map(item => item.value)
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
      {pageEnabled && (
        <div className="share-container">
          <div>
            <p className="title">WHERE TO SHARE</p>
            <div className="content-feed">
              <span>Content feed</span>
              <Switcher
                onChange={event =>
                  setContent({ ...content, main_feed: event.target.checked })
                }
                checked={content.main_feed}
              />
            </div>
            <form className="share-radiobuttons">
              {pages.map(page => (
                <Radiobutton
                  key={page.id}
                  name="share-type"
                  value={page.id}
                  text={page.name}
                  onChange={event => {
                    setContent({
                      ...content,
                      page: parseInt(event.target.value, 10)
                    });
                  }}
                  checked={content.page === page.id}
                />
              ))}
            </form>
          </div>
        </div>
      )}
      <div className="settings">
        <AccordionItem
          title="SETTINGS"
          hint=""
          configurations={configurations}
          isContentModule={false}
          onChange={data =>
            setContent({
              ...content,
              [data.value]: data.event.target.checked
            })
          }
          onChangeNotification={data =>
            setContent({
              ...content,
              [data.value]: data.event.target.value
            })
          }
          defaultOpen
          isContent
          content={content}
          isNotificationsOn={content.send_notification}
          checked={item =>
            content[item.value] !== undefined && content[item.value]
          }
        />
      </div>
    </div>
  );
};

export default NewsInputs;
