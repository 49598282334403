/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
const checkObjProperties = obj => {
  const isObject = value => value === Object(value);

  const isArray = value => Array.isArray(value);

  const permissibleValues = [
    "store_link",
    "youtube_promo_video_url",
    "marketing_url",
    "apple_subscription_shared_secret",
    "bundle_id",
    "sku",
    "apple_id",
    "age_rating",
    "secondary_category"
  ];

  for (const [objKey, objValue] of Object.entries(obj)) {
    if (isArray(objValue)) {
      if (objValue.length) {
        objValue.forEach(element => {
          if (!Object.keys(element).includes("image")) {
            return false;
          }
        });
      } else {
        return false;
      }
    }
    if (isObject(objValue) && !isArray(objValue)) {
      for (const [nestedObjKey, nestedObjValue] of Object.entries(objValue)) {
        if (
          !permissibleValues.includes(nestedObjKey) &&
          nestedObjValue === ""
        ) {
          return false;
        }
      }
    }

    if (objValue === "") {
      return false;
    }
  }
  return true;
};

export default checkObjProperties;
