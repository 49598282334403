export default [
  {
    dropdownTitle: "Product details",
    dropdownKey: "product_detail",
    dropdownItems: [
      { title: "Title", field: "title", form: "input" },
      {
        title: "Short description",
        tooltip: "1-80 characters",
        field: "short_description",
        form: "textarea",
        height: 177
      },
      {
        title: "Full description",
        tooltip: "1-4000 characters",
        field: "full_description",
        form: "textarea",
        height: 387
      },
      {
        title: "Store link",
        tooltip: "Please fill in after publishing (developer task)",
        field: "store_link",
        form: "input"
      }
    ]
  },
  {
    dropdownTitle: "Categorization",
    dropdownKey: "categorization",
    dropdownItems: [
      { title: "App type", field: "app_type", form: "input" },
      { title: "Category", field: "category", form: "input" },
      { title: "Content rating", field: "content_rating", form: "input" }
    ]
  },
  {
    dropdownTitle: "Contact",
    dropdownKey: "contact",
    dropdownItems: [
      {
        title: "Privacy Policy URL",
        field: "privacy_policy_url",
        form: "input"
      },
      {
        title: "YouTube promo video URL",
        field: "youtube_promo_video_url",
        form: "input"
      },
      { title: "Email adress", field: "email_address", form: "input" },
      { title: "Website", field: "website", form: "input" }
    ]
  }
];
