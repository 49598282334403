/* eslint-disable import/no-named-as-default-member */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import appLogo from "../../../../assets/images/settings.svg";
import galleryIcon from "../../../../assets/images/gallery-grid.svg";
import eyeIcon from "../../../../assets/images/eye.svg";
import pictureIcon from "../../../../assets/images/gallery.svg";
import menuIcon from "../../../../assets/images/menu.svg";
import EditMenuButton from "../../../../components/common/EditMenuButton";

const EditMainMenu = ({ handleClick, appId }) => {
  useEffect(() => {});
  const editMenuButtons = [
    { name: "Branding", ico: appLogo },
    {
      name: "Template",
      ico: galleryIcon
    },
    { name: "Design", ico: eyeIcon },
    {
      name: "Modules",
      ico: pictureIcon
    },
    { name: "Menu", ico: menuIcon }
  ];

  return (
    <div className="edit-buttons-container">
      {editMenuButtons.map(button => {
        return (
          <EditMenuButton
            key={button.name}
            name={button.name}
            ico={button.ico}
            handleClick={handleClick}
            appId={appId}
          />
        );
      })}
      <div className="break" />
      <Link
        to={`/dashboard/${appId}/edit-app/publish`}
        onClick={() => {
          handleClick("App Vitals");
        }}
      >
        <div className="app-vitals-button">
          <p>CHANGE APP VITALS</p>
        </div>
      </Link>
    </div>
  );
};

export default EditMainMenu;
