import { connect } from "react-redux";
import React, { useState, useEffect } from "react";

import applicationActions from "../../../redux/actions/application";
import notifications from "../../../utils/notifications";
import picturePlaceholder from "../../../assets/images/img-placeholder@2x.jpg";

import "./index.scss";

const InputVideo = ({
  name,
  placeholder,
  onChange,
  withoutName,
  value,
  styles = {},
  setIsDisabledNext
}) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const fileChangedHandler = event => {
    const file = event.target.files[0];

    if (!file || file.size > 300 * 1024 * 1024) {
      notifications.error({
        msg: "The file is to big or not supported format"
      });
    } else {
      const reader = new FileReader();

      reader.onloadend = async () => {
        setImagePreviewUrl(picturePlaceholder);
        onChange(file);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const deleteImage = async () => {
    onChange(null);
    setImagePreviewUrl(null);
    setIsDisabledNext(false);
  };

  useEffect(() => {
    if (typeof value === "string") {
      setImagePreviewUrl(value);
    }
  }, [value]);

  return (
    <div className="wrapper" style={styles}>
      {imagePreviewUrl && <div className="hover" onClick={deleteImage} />}
      <label htmlFor={name}>
        <div
          className={
            imagePreviewUrl
              ? "custom-input-video selected"
              : "custom-input-video"
          }
        >
          <input
            type="file"
            className="input-img"
            name={name}
            id={name}
            onChange={fileChangedHandler}
            value=""
          />
          {imagePreviewUrl && (
            <div className="image-container">
              <img className="img-preview" src={imagePreviewUrl} alt="icon" />
            </div>
          )}
        </div>
      </label>
      {!withoutName && <p className="title">{placeholder}</p>}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  setIsDisabledNext: isDisabledNext =>
    dispatch(applicationActions.setIsDisabledNext(isDisabledNext))
});

export default connect(null, mapDispatchToProps)(InputVideo);
