import React, { useEffect } from "react";
import { connect } from "react-redux";

import applicationActions from "../../../redux/actions/application";

import Accordion from "../../../components/Accordion";

import "./index.scss";

const commonItems = [
  {
    title: "photo",
    hint:
      "This will be the default settings when you share a photo, but you can change it any time."
  },
  {
    title: "post",
    hint:
      "This will be the default settings when you share a post, but you can change it any time."
  },
  {
    title: "video",
    hint:
      "This will be the default settings when you share a video, but you can change it any time."
  },
  {
    title: "music",
    hint:
      "This will be the default settings when you share a music, but you can change it any time."
  },
  {
    title: "live",
    hint:
      "This will be the default settings when you share a live, but you can change it any time."
  },
  {
    title: "event",
    hint:
      "This will be the default settings when you share a event, but you can change it any time."
  },
  {
    title: "merchandise",
    hint:
      "This will be the default settings when you share a merchandise, but you can change it any time."
  },
  {
    title: "article",
    hint:
      "This will be the default settings when you share an article, but you can change it any time."
  },
  {
    title: "news",
    hint:
      "This will be the default settings when you share news, but you can change it any time."
  }
];

const appModules = [
  {
    title: "Sign up/log in",
    hint: "What kind of data do you need from your users?",
    configurations: [
      { title: "Name", value: "name" },
      { title: "Gender", value: "gender" },
      { title: "Username", value: "username" },
      { title: "Age", value: "age" },
      { title: "Profile picture", value: "profile_picture" },
      { title: "Country", value: "country" },
      { title: "Phone number", value: "phone" }
    ]
  },
  {
    title: "Messages",
    hint: "Can your users send messages to each other?",
    configurations: [{ title: "Messages", value: "messages" }]
  },
  {
    title: "Profile",
    hint: "Can your users write a short bio and use a profile picture?",
    configurations: [
      { title: "Bio", value: "bio" },
      { title: "Profile picture", value: "profile_picture" }
    ]
  }
];
const SetAppBehavior = ({ qrData, updateQrData }) => {
  useEffect(() => {
    updateQrData({
      ...qrData,
      content_module: {
        photo: {},
        post: {},
        video: {},
        music: {},
        live: {},
        event: {},
        merchandise: {}
      },
      app_module: {
        auth: {},
        messages: false,
        profile: {}
      }
    });
  }, []);

  return (
    <div className="behavior-container">
      <div className="behavior-header">
        <h4> Content modules</h4>
      </div>
      <div className="behavior-content">
        <span>
          Choose the functions and content categories you would like to use in
          your app!
        </span>
        <Accordion items={commonItems} />

        <div className="app-modules">
          <h4 className="app-modules-title">App modules</h4>

          <div>
            <Accordion items={appModules} isAppModule />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ application: { qrData } }) => ({ qrData });

const mapDispatchToProps = dispatch => ({
  updateQrData: data => dispatch(applicationActions.updateQrData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SetAppBehavior);
