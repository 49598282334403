/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";

import checkObjProperties from "../../helpers/checkPublishInfo";
import applicationActions from "../../redux/actions/application";
import applicationService from "../../services/application";
import notifications from "../../utils/notifications";

import Input from "../../components/common/Input";
import NotificationPopup from "../../components/NotificationPopup";
import ContentPopup from "../../components/ContentPopup";
import Button from "../../components/common/Button";
import Loader from "../../components/Loader";
import PublishAndroid from "./PublishAndroid";
import PublishIos from "./PublishIos";

import ArrowIcon from "../../assets/images/arrow-down.svg";

import "./index.scss";

const PublishApp = ({
  history,
  draftApp,
  updateDraftApp,
  setEmptyPublishInfo,
  androidPublishInfo,
  iosPublishInfo,
  updateAndroidPublishInfo,
  updateIosPublishInfo,
  isCreatingProcess,
  setServerDraftApp
}) => {
  const [isLoading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("android");
  const [popupText, setPopupText] = useState("");
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [inProgress, setProgress] = useState(false);
  const [androidPublishInfoId, setAndroidPublishInfoId] = useState("");
  const [iosPublishInfoId, setIosPublishInfoId] = useState("");
  const [oldPrice, setOldPrice] = useState("");

  useEffect(() => {
    setOldPrice(draftApp.app_price);
  }, []);

  const { id } = useParams();

  const getPublishInfo = async (publishId, platform) => {
    try {
      const res = await applicationService.getPublishInfo(publishId, platform);
      const updatedPublishInfo = { ...res };

      if (platform === "android") {
        setAndroidPublishInfoId(publishId);

        updatedPublishInfo.screenshots.push({});
        updatedPublishInfo.features.push({});
        updatedPublishInfo.promo_graphics.push({});

        updateAndroidPublishInfo(updatedPublishInfo);
      } else {
        setIosPublishInfoId(publishId);
        updatedPublishInfo.screenshots.push({});

        updateIosPublishInfo(updatedPublishInfo);
      }
    } catch (error) {
      notifications.error({
        msg:
          error.response && error.response.data
            ? Object.values(error.response.data).join(", ")
            : error.message
      });
    }
  };
  useEffect(() => {
    if (draftApp.id !== id) {
      history.push("/create-new-app");
    }
    setLoading(false);

    if (!isCreatingProcess) {
      if (draftApp.android_publish_info && draftApp.ios_publish_info) {
        if (
          draftApp.android_publish_info.length &&
          draftApp.ios_publish_info.length
        ) {
          getPublishInfo(draftApp.android_publish_info[0].id, "android");
          getPublishInfo(draftApp.ios_publish_info[0].id, "ios");
        }
      }
    }
  }, []);

  const updateApp = async () => {
    try {
      const res = await applicationService.updateApplication({
        ...draftApp,
        app_price: parseFloat(draftApp.app_price)
      });

      setServerDraftApp(res);
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };

  const handleSubmit = async () => {
    if (parseFloat(oldPrice) !== parseFloat(draftApp.app_price)) {
      updateApp();
      setOldPrice(draftApp.app_price);
    }
    if (
      !checkObjProperties(iosPublishInfo) &&
      !checkObjProperties(androidPublishInfo)
    ) {
      setPopupText(
        "To submit the application, please fill all fields in Android and iOS Vitals"
      );
      return;
    }
    if (!checkObjProperties(androidPublishInfo)) {
      setPopupText(
        "To submit the application, please fill all fields in Android Vitals"
      );
      return;
    }
    if (!checkObjProperties(iosPublishInfo)) {
      setPopupText(
        "To submit the application, please fill all fields in iOS Vitals"
      );
      return;
    }

    setProgress(true);

    if (androidPublishInfo.high_res_app_icon.image) {
      androidPublishInfo.high_res_app_icon =
        androidPublishInfo.high_res_app_icon.image;

      delete androidPublishInfo.high_res_app_icon.preview;
      delete androidPublishInfo.high_res_app_icon.image;
    }

    if (iosPublishInfo.app_store_icon.image) {
      iosPublishInfo.app_store_icon = iosPublishInfo.app_store_icon.image;

      delete iosPublishInfo.app_store_icon.preview;
      delete iosPublishInfo.app_store_icon.image;
    }

    if (
      !isCreatingProcess &&
      draftApp.android_publish_info.length > 0 &&
      draftApp.ios_publish_info.length > 0
    ) {
      try {
        await applicationService.updatePublishInfo(
          draftApp.android_publish_info[0].id,
          {
            ...androidPublishInfo
          },
          "android"
        );
        await applicationService.updatePublishInfo(
          draftApp.ios_publish_info[0].id,
          {
            ...iosPublishInfo
          },
          "ios"
        );
        setIsOpenPopup(true);
      } catch (err) {
        notifications.error({
          msg:
            err.response && err.response.data
              ? Object.values(err.response.data).join(", ")
              : err.message
        });
      }
    } else {
      try {
        await applicationService.createPublishInfo(
          {
            ...androidPublishInfo,
            app: id
          },
          "android"
        );
        await applicationService.createPublishInfo(
          {
            ...iosPublishInfo,
            app: id
          },
          "ios"
        );

        history.push("/");
      } catch (err) {
        notifications.error({
          msg:
            err.response && err.response.data
              ? Object.values(err.response.data).join(", ")
              : err.message
        });
      }
    }

    setEmptyPublishInfo();
    setProgress(false);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="publish-container">
      {isCreatingProcess && (
        <div className="publish-header">
          <div className="return-button" onClick={() => history.goBack()}>
            <img src={ArrowIcon} className="back-icon" alt="" />
            <p>Publish your app</p>
          </div>
        </div>
      )}
      <div className="general-config-container">
        <p className="general-header">General</p>
        <p className="field-title">App price</p>
        <Input
          onChange={event =>
            updateDraftApp({
              ...draftApp,
              app_price: event.target.value
            })
          }
          value={draftApp.app_price}
        />
      </div>
      <div className="publish-content">
        <div className="tabs-container">
          <div
            className={classnames({
              tab: true,
              active: activeTab === "android"
            })}
            onClick={() => setActiveTab("android")}
          >
            <span className="tab-title">Android</span>
          </div>
          <div
            className={classnames({
              tab: true,
              active: activeTab === "ios"
            })}
            onClick={() => setActiveTab("ios")}
          >
            <span className="tab-title">Ios</span>
          </div>
        </div>
        {activeTab === "android" ? (
          <PublishAndroid
            androidPublishInfo={androidPublishInfo}
            updateAndroidPublishInfo={updateAndroidPublishInfo}
            isCreatingProcess={isCreatingProcess}
            publishInfoId={androidPublishInfoId}
            draftApp={draftApp}
          />
        ) : (
          <PublishIos
            iosPublishInfo={iosPublishInfo}
            updateIosPublishInfo={updateIosPublishInfo}
            isCreatingProcess={isCreatingProcess}
            publishInfoId={iosPublishInfoId}
            draftApp={draftApp}
          />
        )}

        <Button handleClick={handleSubmit} className="submit-btn" type="submit">
          Submit
          {inProgress && (
            <div className="lds-ring">
              <div />
              <div />
              <div />
              <div />
            </div>
          )}
        </Button>
      </div>

      {popupText !== "" && (
        <NotificationPopup
          setNotificationPopup={() => setPopupText("")}
          buttonText="Okay"
          notificationText={popupText}
        />
      )}

      {isOpenPopup && (
        <ContentPopup
          UplodingData={{ success: isOpenPopup }}
          onExit={() => setIsOpenPopup(false)}
          setIsOpenPopup={setIsOpenPopup}
          goback={() => history.push(`/dashboard/${draftApp.id}/edit-app/`)}
          msg="Changes successfully saved"
        />
      )}
    </div>
  );
};

const mapStateToProps = ({
  application: {
    draftApp,
    androidPublishInfo,
    iosPublishInfo,
    isCreatingProcess
  }
}) => ({
  draftApp,
  androidPublishInfo,
  iosPublishInfo,
  isCreatingProcess
});

const mapDispatchToProps = dispatch => ({
  setEmptyPublishInfo: () => dispatch(applicationActions.setEmptyPublishInfo()),
  updateAndroidPublishInfo: data =>
    dispatch(applicationActions.updateAndroidPublishInfo(data)),
  updateIosPublishInfo: data =>
    dispatch(applicationActions.updateIosPublishInfo(data)),
  updateDraftApp: res => dispatch(applicationActions.updateDraftApp(res)),
  setServerDraftApp: serverDraftApp =>
    dispatch(applicationActions.setServerDraftApp(serverDraftApp))
});

export default connect(mapStateToProps, mapDispatchToProps)(PublishApp);
