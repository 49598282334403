/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default-member */
import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  withRouter,
  useParams,
  Redirect
} from "react-router-dom";
import { connect } from "react-redux";

import Button from "../../../components/common/Button";
import QRCodeImg from "../../../components/common/QRCode";
import ContentPopup from "../../../components/ContentPopup";

import applicationActions from "../../../redux/actions/application";
import applicationService from "../../../services/application";
import mainActions from "../../../redux/actions/main";
import SetAppLogo from "../../CreateApp/SetAppLogo";
import SetAppTemplate from "../../CreateApp/SetAppTemplate";
import SetAppDesign from "../../CreateApp/SetAppDesign";
import SetAppNavigation from "../../CreateApp/SetAppNavigation";
import SetAppBehavior from "../../CreateApp/SetAppBehavior";
import PublishApp from "../../PublishApp";
import EditMainMenu from "./EditMainMenu";
import ArrowDownIcon from "../../../assets/images/arrow-down.svg";
import notifications from "../../../utils/notifications";

import "./index.scss";

const EditApp = ({
  history,
  updateDraftApp,
  draftApp,
  qrData,
  isShowQRCodePopup,
  setQRCodePopup,
  setServerDraftApp,
  currentUser,
  serverDraftApp,
  location,
  updateQrData
}) => {
  const { id } = useParams();
  const [activeMenuItem, setMenuItem] = useState("");
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  useEffect(() => {
    return () => updateQrData({});
  }, []);

  const updateApplication = async isRepublish => {
    try {
      if (isRepublish) {
        await applicationService.rePublishApp(draftApp.id);
      }
      const res = await applicationService.updateApplication(draftApp);

      setIsOpenPopup(true);

      updateDraftApp(res);
      setServerDraftApp(res);
      updateQrData({});
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };

  return currentUser.role === "admin" ? (
    activeMenuItem &&
    location.pathname.substr(location.pathname.length - 1) !== "/" ? (
      <div className="edit-app-container">
        {isOpenPopup && (
          <ContentPopup
            UplodingData={{ success: isOpenPopup }}
            onExit={() => setIsOpenPopup(false)}
            setIsOpenPopup={setIsOpenPopup}
            goback={() => setMenuItem("")}
            msg="Changes successfully saved"
          />
        )}
        <div
          className="edit-app-header"
          onClick={() => {
            setMenuItem("");
            updateQrData({});
            updateDraftApp({
              ...serverDraftApp
            });
            history.goBack();
          }}
        >
          <img src={ArrowDownIcon} alt="" />
          <p>{activeMenuItem}</p>
        </div>
        <div className="edit-app-content">
          <div className="edit-app-wrapper">
            <Switch>
              <Route
                path="/dashboard/:id/edit-app/branding/"
                component={SetAppLogo}
              />
              <Route
                path="/dashboard/:id/edit-app/template/"
                component={SetAppTemplate}
              />
              <Route
                path="/dashboard/:id/edit-app/design/"
                component={SetAppDesign}
              />
              <Route
                path="/dashboard/:id/edit-app/modules/"
                component={SetAppBehavior}
              />
              <Route
                path="/dashboard/:id/edit-app/menu/"
                component={SetAppNavigation}
              />
              <Route
                path="/dashboard/:id/edit-app/publish/"
                component={PublishApp}
              />
            </Switch>
            {activeMenuItem !== "App Vitals" && (
              <div className="edit-app-buttons">
                {activeMenuItem === "Branding" && draftApp.is_published ? (
                  <Button
                    handleClick={() => updateApplication(true)}
                    className="save-btn"
                    type="submit"
                  >
                    Save and publish
                  </Button>
                ) : (
                  <Button
                    handleClick={() => updateApplication()}
                    className="save-btn"
                    type="submit"
                  >
                    Save
                  </Button>
                )}
              </div>
            )}
          </div>

          <div
            className={
              activeMenuItem &&
              ["Branding", "Template", "App Vitals"].includes(activeMenuItem)
                ? "qr-code"
                : "qr-code show"
            }
            onClick={() => {
              setQRCodePopup(!isShowQRCodePopup);
            }}
          >
            <QRCodeImg
              value={JSON.stringify({
                id: draftApp.id,
                ...qrData
              })}
              size="116"
            />
          </div>
        </div>
      </div>
    ) : (
      <EditMainMenu handleClick={setMenuItem} appId={id} />
    )
  ) : (
    <Redirect to="/" />
  );
};

const mapStateToProps = ({
  application: { draftApp, qrData, serverDraftApp },
  main: { isShowQRCodePopup },
  auth: { currentUser }
}) => ({ draftApp, qrData, isShowQRCodePopup, currentUser, serverDraftApp });

const mapDispatchToProps = dispatch => ({
  updateDraftApp: res => dispatch(applicationActions.updateDraftApp(res)),
  setQRCodePopup: isShowQRCodePopup =>
    dispatch(mainActions.setQRCodePopup(isShowQRCodePopup)),
  setServerDraftApp: serverDraftApp =>
    dispatch(applicationActions.setServerDraftApp(serverDraftApp)),
  updateQrData: data => dispatch(applicationActions.updateQrData(data))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditApp)
);
