/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { SlideDown } from "react-slidedown";

import applicationActions from "../../../redux/actions/application";
import arrow from "../../../assets/images/arrow-down-white.svg";

import "react-slidedown/lib/slidedown.css";
import "./index.scss";

const FontPicker = ({ name, fonts, onChange, defaultFont }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFontName, setFontName] = useState(fonts[0].name);

  useEffect(() => {
    const index = fonts.findIndex(font => font.name === defaultFont);

    setFontName(fonts[index].name);
  }, []);

  const handleClick = font => {
    setFontName(font.name);
    setIsOpen(!isOpen);
    onChange(font.text);
  };

  return (
    <div>
      <div className="font-picker" onClick={() => setIsOpen(!isOpen)}>
        <div className="font-picker-header">
          <p className="title" style={{ fontFamily: `"${selectedFontName}"` }}>
            {defaultFont}
          </p>
          <img
            className={classnames({ "arrow-up": isOpen })}
            src={arrow}
            alt="arrow"
          />
        </div>
        <SlideDown closed={!isOpen} className="scrolling">
          <ul className="font-picker-content">
            {fonts.map(font => (
              <li
                key={font.name}
                className="font-item"
                text={font.text}
                onClick={() => {
                  handleClick({ text: font.text, name: font.name });
                }}
              >
                <p style={{ fontFamily: font.name }}>{font.text}</p>
              </li>
            ))}
          </ul>
        </SlideDown>
      </div>
      <div className="font-picker-footer">
        <p>{name}</p>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  updateDraftApp: res => dispatch(applicationActions.updateDraftApp(res))
});

const mapStateToProps = ({ application: { draftApp } }) => ({ draftApp });

export default connect(mapStateToProps, mapDispatchToProps)(FontPicker);
