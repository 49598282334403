export default [
  { name: "Roboto", text: "Roboto" },
  { name: "Open Sans", text: "Open Sans" },
  { name: "Lato", text: "Lato" },
  { name: "Oswald", text: "Oswald" },
  { name: "Roboto Condensed", text: "Roboto Condensed" },
  { name: "Slabo 27px", text: "Slabo 27px" },
  { name: "Montserrat", text: "Montserrat" },
  { name: "Source Sans Pro", text: "Source Sans Pro" },
  { name: "Raleway", text: "Raleway" },
  { name: "PT Sans", text: "PT Sans" },
  { name: "Roboto Slab", text: "Roboto Slab" },
  { name: "Lora", text: "Lora" },
  { name: "Merriweather", text: "Merriweather" },
  { name: "Ubuntu", text: "Ubuntu" },
  { name: "Noto Sans", text: "Noto Sans" },
  { name: "Playfair Display", text: "Playfair Display" },
  { name: "PT Sans Narrow", text: "PT Sans Narrow" },
  { name: "Arimo", text: "Arimo" },
  { name: "PT Serif", text: "PT Serif" },
  { name: "Poppins", text: "Poppins" }
];
