/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import React from "react";

import applicationService from "../../../services/application";

import Dropdown from "../Dropdown";
import InputImg from "../../../components/common/InputImg";
import dropdowns from "../../../constants/PublishAndroidDropdowns";

import "./index.scss";

const PublishAndroid = ({
  androidPublishInfo,
  updateAndroidPublishInfo,
  isCreatingProcess,
  publishInfoId,
  draftApp
}) => {
  const addNewIcon = async (image, index, preview, context) => {
    let iconsArray;
    let imageType;

    switch (context) {
      case "screenshots":
        iconsArray = androidPublishInfo.screenshots;
        imageType = "screenshot";
        break;
      case "features":
        iconsArray = androidPublishInfo.features;
        imageType = "feature";
        break;
      case "promo_graphics":
        iconsArray = androidPublishInfo.promo_graphics;
        imageType = "promo_graphic";
        break;
      default:
        break;
    }

    const oldIcon = iconsArray[index];

    let updatedArray = [];

    if (image === null) {
      if (!isCreatingProcess) {
        await applicationService.deletePublishIcon(
          publishInfoId,
          iconsArray[index].id,
          "android"
        );
      }

      iconsArray[index] = {};

      if (iconsArray.length > 1) {
        iconsArray.splice(index, 1);
      }

      updatedArray = [...iconsArray];
    } else {
      iconsArray[index] = { id: index + 1, image, preview };

      const isOldIconEmpty = !oldIcon.name;

      if (iconsArray.length + 1 === 9 || !isOldIconEmpty) {
        updatedArray = [...iconsArray];
      } else {
        updatedArray = [...iconsArray, {}];
      }
    }

    updateAndroidPublishInfo({
      ...androidPublishInfo,
      [context]: [...updatedArray]
    });

    if (
      !isCreatingProcess &&
      draftApp.android_publish_info.length > 0 &&
      image !== null
    ) {
      await applicationService.setPublishIcons(
        publishInfoId,
        {
          image,
          image_type: imageType
        },
        "android"
      );
    }
  };

  return (
    <div className="publish-android">
      <div className="columns-container">
        <div className="app-config-column">
          {dropdowns.map(dropdown => (
            <Dropdown
              key={dropdown.dropdownTitle}
              dropdown={dropdown}
              publishInfo={androidPublishInfo}
              updatePublishInfo={updateAndroidPublishInfo}
            />
          ))}
        </div>
        <div className="app-icons-column">
          <div className="screenshots">
            <p>SCREENSHOTS</p>
            <span>
              JPEG or 24-bit PNG (no alpha). Max lenght for any side: 3840 px.
              At least 2, maximum 8 screenshots.
            </span>
            <div className="screenshots-icons">
              {androidPublishInfo.screenshots.map((screenshot, index) => (
                <div className="screenshot" key={screenshot.id}>
                  <InputImg
                    name={`screenshot-${index}`}
                    value={
                      screenshot.preview ? screenshot.preview : screenshot.image
                    }
                    onChange={(image, preview) =>
                      addNewIcon(image, index, preview, "screenshots")
                    }
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="high-res-icon">
            <p>High-Res APP ICON</p>

            <span>512 x 512 px, 32-bit PNG, no transparency</span>
            <div className="icon-container">
              <InputImg
                name="high-res-icon"
                value={
                  androidPublishInfo.high_res_app_icon.preview
                    ? androidPublishInfo.high_res_app_icon.preview
                    : androidPublishInfo.high_res_app_icon
                }
                onChange={(image, preview) =>
                  updateAndroidPublishInfo({
                    ...androidPublishInfo,
                    high_res_app_icon: { image, preview }
                  })
                }
              />
            </div>
          </div>
          <div className="feature-icons">
            <p>Feature</p>

            <span>1024x500 JPG or 24-bit PNG, no transparency</span>

            <div className="features">
              {androidPublishInfo.features.map((feature, index) => (
                <div className="feature-icon" key={feature.id}>
                  <InputImg
                    name={`feature-${index}`}
                    value={feature.preview ? feature.preview : feature.image}
                    onChange={(image, preview) =>
                      addNewIcon(image, index, preview, "features")
                    }
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="promo-graphic-icons">
            <p>PROMO GRAPHIC</p>
            <span>optional, 180x120 px JPG or 24-bit PNG, no transparency</span>

            <div className="promo-graphics">
              {androidPublishInfo.promo_graphics.map((promo, index) => (
                <div className="promo-icon" key={promo.id}>
                  <InputImg
                    name={`promo-${index}`}
                    value={promo.preview ? promo.preview : promo.image}
                    onChange={(image, preview) =>
                      addNewIcon(image, index, preview, "promo_graphics")
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublishAndroid;
