import React from "react";

import "./index.scss";

const AppItem = props => {
  const { name, date, thumbnail, draft, onClick, id, userRole = "" } = props;
  return (
    <div className="app-item">
      <div className={thumbnail ? "thumbnail" : "thumbnail default"}>
        <div
          className={userRole !== "admin" ? "click without-edit" : "click"}
          onClick={() => {
            onClick(`dashboard/${id}/app-overview/`);
          }}
        />
        {userRole === "admin" && (
          <div
            className={draft ? "edit-draft" : "edit-live"}
            onClick={() => {
              onClick(`dashboard/${id}/edit-app/`);
            }}
          />
        )}
        {thumbnail && <img alt={name} src={thumbnail} />}
      </div>
      <div className="footer">
        <p className="title">{name}</p>
        <p className="date">{date}</p>
      </div>
    </div>
  );
};
export default AppItem;
