/* eslint-disable import/no-named-as-default-member */
import React from "react";
import { connect } from "react-redux";

import Input from "../../../components/common/Input";
import InputImg from "../../../components/common/InputImg";
import applicationActions from "../../../redux/actions/application";

import "./index.scss";

const SetAppLogo = ({
  draftApp,
  isCreatingProcess,
  updateDraftApp,
  nameInputError,
  urlInputError
}) => {
  return (
    <div>
      {!isCreatingProcess && (
        <p className="info-text">
          ANY CHANGES HERE WILL REQUIRE AN APP STORE UPDATE
        </p>
      )}
      <p className="title">NAME</p>
      <Input
        inputerror={nameInputError}
        onChange={event =>
          updateDraftApp({ ...draftApp, name: event.target.value })
        }
        value={draftApp.name ? draftApp.name : ""}
      />
      <p className="title">URL IDENTIFIER</p>
      <Input
        inputerror={urlInputError}
        readOnly={!isCreatingProcess}
        onChange={event =>
          updateDraftApp({ ...draftApp, url_identifier: event.target.value })
        }
        value={draftApp.url_identifier ? draftApp.url_identifier : ""}
      />
      <p className="title branding">BRANDING</p>
      <p className="input-description-branding">
        Requirements for Logo: PNG with transparency, 1500*500 px
        <br />
        Requirements for App icon: PNG with transparency, 2048*2048 px
        <br />
        Requirements for Splash: PNG with no transparency, 1242*2688 px
      </p>
      <div className="input-container">
        <InputImg
          onChange={result => updateDraftApp({ ...draftApp, logo: result })}
          value={draftApp.logo ? draftApp.logo : ""}
          name="logo"
          placeholder="Logo"
        />
        <InputImg
          onChange={result => updateDraftApp({ ...draftApp, app_icon: result })}
          value={draftApp.app_icon ? draftApp.app_icon : ""}
          name="app-icon"
          placeholder="App icon"
        />
        <InputImg
          name="splash_screen"
          onChange={result =>
            updateDraftApp({ ...draftApp, splash_screen: result })
          }
          value={draftApp.splash_screen ? draftApp.splash_screen : ""}
          placeholder="Splash image"
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ application: { draftApp, isCreatingProcess } }) => ({
  draftApp,
  isCreatingProcess
});

const mapDispatchToProps = dispatch => ({
  updateDraftApp: res => dispatch(applicationActions.updateDraftApp(res))
});

export default connect(mapStateToProps, mapDispatchToProps)(SetAppLogo);
