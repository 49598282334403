/* eslint-disable import/no-named-as-default-member */
import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import _ from "lodash";

import useOnClickOutside from "../../hooks/useClickOutside";

import arrow from "../../assets/images/arrow-down.svg";
import Logo from "../../assets/images/logo.png";

import { removeAllTokens } from "../../helpers/auth";
import applicationService from "../../services/application";
import mainActions, { setContentInProgress } from "../../redux/actions/main";
import applicationActions from "../../redux/actions/application";
import notifications from "../../utils/notifications";

import Button from "../common/Button";
import Popup from "../Popup";

import "./index.scss";

const Header = ({
  currentUser,
  isCreatingProcess,
  isBlurred,
  setBlur,
  setCreateProcess,
  setPopup,
  isShowPopup,
  history,
  draftApp,
  serverDraftApp,
  setDraftEmpty,
  isContentInProgress,
  location
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);

  const dropdownElement = useRef();

  useOnClickOutside(dropdownElement, () => {
    if (isDropdownOpen) {
      setDropdownOpen(false);
      setBlur(false);
    }
  });

  const logOut = () => {
    removeAllTokens();

    window.location.href = "/login";
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
    setBlur(!isBlurred);
  };

  const checkAppCreatingProgress = (action, actionName = "") => {
    setCurrentAction(() => () => action());
    if (location.pathname.includes("-content") && isContentInProgress) {
      setPopup(true);
    } else if (
      !_.isEmpty(draftApp) &&
      !_.isEmpty(serverDraftApp) &&
      !_.isEqual(draftApp, serverDraftApp)
    ) {
      setPopup(true);
    } else {
      if (actionName !== "create-new-app") {
        setCreateProcess(false);
      }
      setDraftEmpty();
      action();
    }
  };

  const redirect = url => {
    history.push(url);
  };

  const handleSave = async () => {
    try {
      if (!draftApp.id) {
        await applicationService.createApp(draftApp);
      } else {
        await applicationService.updateApplication(draftApp);
      }
      setDraftEmpty();
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };

  const discard = async () => {
    setCreateProcess(false);
    setDraftEmpty();
    setContentInProgress(false);
  };

  return (
    <div className="header-container">
      {isShowPopup && (
        <Popup
          onResolve={isContentInProgress ? () => {} : handleSave}
          question={
            isContentInProgress
              ? "Do you want to save your progress?"
              : "Do you want to save your progress before leaving ?"
          }
          onReject={discard}
          onExit={currentAction}
          onResolveTitle={isContentInProgress ? "YES" : "SAVE AS WIP"}
          onRejectTitle={isContentInProgress ? "NO" : "DISCARD CHANGES"}
          withoutActions={isContentInProgress}
        />
      )}
      <div className="header-content">
        <img
          src={Logo}
          alt="app-logo"
          className="main-logo"
          onClick={() => {
            checkAppCreatingProgress(() => redirect("/"));
          }}
        />
        {currentUser && (
          <div className="logged-in-controllers">
            {!isCreatingProcess && currentUser.role === "admin" && (
              <Button
                className="create-app-btn"
                handleClick={() => {
                  setCreateProcess(true);
                  checkAppCreatingProgress(
                    () => redirect("/create-new-app"),
                    "create-new-app"
                  );
                }}
              >
                Create new app
              </Button>
            )}

            <div
              ref={dropdownElement}
              className={
                isDropdownOpen
                  ? "current-user-dropdown"
                  : "current-user-dropdown hide"
              }
              onClick={toggleDropdown}
            >
              <button
                type="button"
                className={classnames({
                  "logged-in-user": true,
                  blurred: isDropdownOpen
                })}
              >
                {currentUser.username}
              </button>
              <img
                src={arrow}
                className={classnames({ "arrow-up": isDropdownOpen })}
                alt="arrow"
              />

              {isDropdownOpen && (
                <div className="logout-btn-container">
                  <Button
                    className="logout-btn"
                    handleClick={() => checkAppCreatingProgress(logOut)}
                  >
                    Log out
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = ({
  auth: { currentUser },
  application: { isCreatingProcess, draftApp, serverDraftApp },
  main: { isBlurred, isShowPopup, isContentInProgress }
}) => ({
  currentUser,
  isCreatingProcess,
  isBlurred,
  isShowPopup,
  draftApp,
  serverDraftApp,
  isContentInProgress
});

const mapDispatchToProps = dispatch => ({
  setBlur: isBlur => dispatch(mainActions.setBlur(isBlur)),
  setCreateProcess: isCreatingProcess =>
    dispatch(applicationActions.setCreateProcess(isCreatingProcess)),
  setPopup: isShowPopup => dispatch(mainActions.setPopup(isShowPopup)),
  setDraftEmpty: () => dispatch(applicationActions.setDraftEmpty())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
