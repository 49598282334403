/* eslint-disable no-shadow */
import React from "react";
import { connect } from "react-redux";
import AccordionItem from "./AccordionItem";
import applicationActions from "../../redux/actions/application";

const defaultConfigurations = [
  { title: "Likes", value: "likes" },
  { title: "Premium", value: "premium" },
  { title: "Comments", value: "comment" },
  { title: "Content feed", value: "content_feed" },
  { title: "Share", value: "share" },
  { title: "Notifications", value: "notification" }
];

const Accordion = ({
  items,
  isAppModule,
  updateDraftApp,
  updateQrData,
  draftApp,
  qrData
}) => {
  return (
    <div className="accordion-container">
      {items.map(item => {
        const module = isAppModule ? "app_module" : "content_module";

        const configurations = isAppModule
          ? item.configurations
          : defaultConfigurations;

        let key = item.title.toLowerCase();
        if (key === "sign up/log in") {
          key = "auth";
        }
        return (
          <AccordionItem
            key={item.title}
            title={item.title}
            hint={item.hint}
            configurations={configurations}
            isContentModule={!isAppModule}
            onChange={data => {
              updateDraftApp({
                ...draftApp,
                [module]: {
                  ...draftApp[module],
                  [key]: {
                    ...draftApp[module][key],
                    [data.value.toLowerCase()]: data.event.target.checked
                  }
                }
              });
              updateQrData({
                ...qrData,
                [module]: {
                  ...qrData[module],
                  [key]: {
                    ...qrData[module][key],
                    [data.value.toLowerCase()]: data.event.target.checked
                  }
                }
              });
            }}
            checked={item =>
              draftApp[module][key] !== undefined &&
              draftApp[module][key][item.value.toLowerCase()]
            }
          />
        );
      })}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  updateDraftApp: res => dispatch(applicationActions.updateDraftApp(res)),
  updateQrData: data => dispatch(applicationActions.updateQrData(data))
});

const mapStateToProps = ({ application: { draftApp, qrData } }) => ({
  draftApp,
  qrData
});

export default connect(mapStateToProps, mapDispatchToProps)(Accordion);
