import React from "react";
import { Link, withRouter } from "react-router-dom";

import "./index.scss";

const EditMenuButton = ({ name, ico, appId, handleClick }) => {
  return (
    <Link
      to={`/dashboard/${appId}/edit-app/${name.toLowerCase()}`}
      onClick={() => {
        handleClick(name);
      }}
    >
      <div className="edit-button">
        <div className="content">
          <img src={ico} alt="" /> <p>{name} </p>
        </div>
      </div>
    </Link>
  );
};

export default withRouter(EditMenuButton);
