import fonts from "./FontsDropdown";

const colorPickersData = [
  {
    name: "Color A",
    reduxName: "color_a",
    value: "#131415"
  },
  {
    name: "Color B",
    reduxName: "color_b",
    value: "#242527"
  },
  {
    name: "Highlight",
    reduxName: "color_highlight",
    value: "#720632"
  },
  {
    name: "Text and Icons",
    reduxName: "color_text_icon",
    value: "#ffffff"
  },
  {
    name: "Overlay",
    reduxName: "color_overlay",
    value: "#131415"
  },
  {
    name: "Post Bubble",
    reduxName: "color_post",
    value: "#242527"
  },
  {
    name: "Call to action",
    reduxName: "color_call_action",
    value: "#ffffff"
  }
];

const fontPickersData = [
  {
    name: "Headline, Menu, Buttons",
    reduxName: "primary",
    fonts
  },
  {
    name: "Text and Comments",
    reduxName: "secondary",
    fonts
  }
];

export { colorPickersData, fontPickersData };
