import axios from "axios";

const getActivites = async queries => {
  if (queries.nextUrl && queries.nextUrl !== "") {
    const { data } = await axios({
      baseURL: queries.nextUrl,
      method: "get"
    });
    return data;
  }
  const { data } = await axios.get(`/activities/?app=${queries.app}&limit=10`);

  return data;
};

const getActivity = async id => {
  const { data } = await axios.get(`/activities/${id}`);

  return data;
};

export { getActivity, getActivites };

export default {
  getActivity,
  getActivites
};
