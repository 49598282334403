import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import applicationActions from "../../../redux/actions/application";

import CheckboxImg from "../../../components/common/CheckboxImg";

import TemplateImg from "../../../assets/images/template.png";

// import lightImage from "../../../assets/images/template-light.png";
import templateGif from "../../../assets/images/ezgif-6-6b9f94a29959.gif";
import phoneFrame from "../../../assets/images/img-phone-empty.svg";

import "./index.scss";

const SetAppTemplate = ({ draftApp, updateDraftApp }) => {
  return (
    <div className="radio-buttons-container">
      {/* <CheckboxImg
        name="theme"
        img={lightImage}
        value={1}
        imgClass="image"
        onChange={event =>
          updateDraftApp({
            ...draftApp,
            template: parseInt(event.target.value, 10)
          })
        }
        checked={draftApp.template === 1 || !draftApp.template}
      /> */}

      <div
        className="static-template"
        onClick={() =>
          updateDraftApp({
            ...draftApp,
            template: 2
          })
        }
      >
        <div
          className={classnames({
            "custom-template": true,
            active: draftApp.template === 2 || !draftApp.template
          })}
        >
          <img className="frame" src={phoneFrame} alt="" />
          <img className="review-gif" src={templateGif} alt="" />
        </div>

        <CheckboxImg
          name="theme"
          img={TemplateImg}
          value={2}
          imgClass="image"
          onChange={event =>
            updateDraftApp({
              ...draftApp,
              template: parseInt(event.target.value, 10)
            })
          }
          checked={draftApp.template === 2 || !draftApp.template}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ application: { draftApp } }) => ({ draftApp });

const mapDispatchToProps = dispatch => ({
  updateDraftApp: res => dispatch(applicationActions.updateDraftApp(res))
});

export default connect(mapStateToProps, mapDispatchToProps)(SetAppTemplate);
