/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import notifications from "../../utils/notifications";
import applicationService from "../../services/application";
import applicationActions from "../../redux/actions/application";

import dropDownIcon from "../../assets/images/ic-drop.svg";
import AppRowList from "../../components/AppRowList";
import Loader from "../../components/Loader";

import "./index.scss";

const AppList = ({ appsList, setAppList, setCreateProcess }) => {
  const [isLoading, setLoading] = useState(true);
  const [nextDraftUrl, setNextDraftUrl] = useState("");
  const [nextLiveUrl, setNextLiveUrl] = useState("");
  const [liveScrollHeight, setLiveScrollHeight] = useState("250px");
  const [draftScrollHeight, setDraftScrollHeight] = useState("250px");
  const userRole = useSelector(state => state.auth.currentUser.role);

  useEffect(() => {
    setCreateProcess(false);
  });
  const calcScrollHeight = appsCount => {
    if (appsCount > 0 && appsCount < 5) {
      return "465px";
    }
    if (appsCount > 4) {
      return "865px";
    }
    return "250px";
  };

  const fetchApplications = async () => {
    try {
      const {
        next: nextLiveUrl,
        results: resLive
      } = await applicationService.getApplications({
        is_published: "true",
        limit: "10"
      });
      setNextLiveUrl(nextLiveUrl);
      setLiveScrollHeight(calcScrollHeight(resLive.length));

      const {
        next: nextDraftUrl,
        results: resDraft
      } = await applicationService.getApplications({
        is_published: "false",
        limit: "10"
      });
      setNextDraftUrl(nextDraftUrl);
      const appsList = {};

      setDraftScrollHeight(calcScrollHeight(resDraft.length));

      appsList.draft = resDraft;
      appsList.live = resLive;

      setAppList(appsList);
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
    setLoading(false);
  };

  const loadMoreDraftApp = async () => {
    try {
      const { results, next } = await applicationService.getApplications({
        nextUrl: nextDraftUrl
      });
      await setAppList({ ...appsList, draft: [...appsList.draft, ...results] });
      setNextDraftUrl(next);
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };

  const loadMoreLiveApp = async () => {
    try {
      const { results, next } = await applicationService.getApplications({
        nextUrl: nextLiveUrl
      });
      await setAppList({ ...appsList, live: [...appsList.live, ...results] });
      setNextLiveUrl(next);
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div id="wrapper">
      <div id="container">
        <span className="title-text">All apps </span>
        <img className="dropdown-icon" src={dropDownIcon} alt="" />
        {userRole === "admin" && (
          <p className="description">
            Select an app to edit or view it on your phone!
          </p>
        )}
        <InfiniteScroll
          dataLength={appsList.live.length}
          next={loadMoreLiveApp}
          height={liveScrollHeight}
          hasMore={nextLiveUrl !== null}
        >
          <AppRowList name="LIVE APPS" apps={appsList.live} draft={false} />
        </InfiniteScroll>
        <InfiniteScroll
          dataLength={appsList.draft.length}
          next={loadMoreDraftApp}
          height={draftScrollHeight}
          hasMore={nextDraftUrl !== null}
        >
          <AppRowList name="DRAFTS" apps={appsList.draft} draft />
        </InfiniteScroll>
      </div>
    </div>
  );
};

const mapStateToProps = ({ application: { appsList } }) => ({ appsList });

const mapDispatchToProps = dispatch => ({
  setAppList: appsList => dispatch(applicationActions.setAppsList(appsList)),
  setCreateProcess: isCreating =>
    dispatch(applicationActions.setCreateProcess(isCreating))
});
export default connect(mapStateToProps, mapDispatchToProps)(AppList);
