/* eslint-disable import/no-extraneous-dependencies */
import React from "react";

import applicationService from "../../../services/application";
import Dropdown from "../Dropdown";
import InputImg from "../../../components/common/InputImg";
import dropdowns from "../../../constants/PublishIosDropdowns";

import "./index.scss";

const PublishIos = ({
  iosPublishInfo,
  updateIosPublishInfo,
  isCreatingProcess,
  publishInfoId,
  draftApp
}) => {
  const addNewIcon = (image, index, preview, context) => {
    const iconsArray = iosPublishInfo.screenshots;

    const oldIcon = iconsArray[index];

    let updatedArray = [];

    if (image === null) {
      if (!isCreatingProcess) {
        applicationService.deletePublishIcon(
          publishInfoId,
          iconsArray[index].id,
          "ios"
        );
      }
      iconsArray[index] = {};

      if (iconsArray.length > 1) {
        iconsArray.splice(index, 1);
      }

      updatedArray = [...iconsArray];
    } else {
      iconsArray[index] = { id: index + 1, image, preview };

      const isOldIconEmpty = !oldIcon.name;

      if (iconsArray.length + 1 === 31 || !isOldIconEmpty) {
        updatedArray = [...iconsArray];
      } else {
        updatedArray = [...iconsArray, {}];
      }
    }

    updateIosPublishInfo({
      ...iosPublishInfo,
      [context]: [...updatedArray]
    });

    if (
      !isCreatingProcess &&
      draftApp.ios_publish_info.length > 0 &&
      image !== null
    ) {
      applicationService.setPublishIcons(
        publishInfoId,
        {
          image,
          image_type: "screenshot"
        },
        "ios"
      );
    }
  };

  return (
    <div className="publish-ios">
      <div className="columns-container">
        <div className="app-config-column">
          {dropdowns.map(dropdown => (
            <Dropdown
              key={dropdown.dropdownTitle}
              dropdown={dropdown}
              publishInfo={iosPublishInfo}
              updatePublishInfo={updateIosPublishInfo}
            />
          ))}
        </div>
        <div className="app-icons-column">
          <div className="app-store-icon-container">
            <p>App store icon</p>
            <span>PNG with no transparency, 1024*1024px</span>
            <div className="app-store-icon">
              <InputImg
                name="app-store-icon"
                value={
                  iosPublishInfo.app_store_icon.preview
                    ? iosPublishInfo.app_store_icon.preview
                    : iosPublishInfo.app_store_icon
                }
                onChange={(image, preview) =>
                  updateIosPublishInfo({
                    ...iosPublishInfo,
                    app_store_icon: { image, preview }
                  })
                }
              />
            </div>
          </div>
          <div className="screenshots">
            <p>App previews and screenshots</p>

            <span>Up to 3 app previews and 10 screenshots</span>
            <br />
            <span>PNG with no transparency, 1242*2688px</span>
            <div className="screenshots-icons">
              {iosPublishInfo.screenshots.map((screenshot, index) => (
                <div className="screenshot" key={screenshot.id}>
                  <InputImg
                    name={`screenshot-${index}`}
                    value={
                      screenshot.preview ? screenshot.preview : screenshot.image
                    }
                    onChange={(image, preview) =>
                      addNewIcon(image, index, preview, "screenshots")
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublishIos;
