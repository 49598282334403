import React, { useState } from "react";
import classnames from "classnames";
import RingLoader from "react-spinners/MoonLoader";
import { SlideDown } from "react-slidedown";

import editIcon from "../../../assets/images/settings-1365.svg";
import picturePlaceholder from "../../../assets/images/img-placeholder.jpg";
import tickIcon from "../../../assets/images/tick.svg";
import closeIcon from "../../../assets/images/close-icon.svg";
import arrow from "../../../assets/images/arrow-down.svg";

import RolePicker from "../RolePicker";
import Input from "../../common/Input";
import Button from "../../common/Button";
import TagButton from "../TagButton";

import userService from "../../../services/users";
import notifications from "../../../utils/notifications";

import "./index.scss";

const UsersListItem = (props) => {
    
    const [user, setUser] = useState({...props.user});
    const [isEditing, setIsEditing] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [showTags, setShowTags] = useState(false);
    const [tags, setTags] = useState([...props.user.tags]);
    const [tagInput, setTagInput] = useState('');

    let imgSrc = picturePlaceholder;

    if (user.picture !== null && user.picture !== "") {
        imgSrc = user.picture;
    }

    const updateUser = async () => {
      setIsUpdating(true);
      try {

        const body = {
          userId: user.id, 
          appId: props.app_id
        }
        if (user.role !== props.user.role) {
          body.updatedRole = user.role;
        }
        if (tags !== props.user.tags) {
          body.updatedTags = tags;
        }

        const updatedUser = await userService.updateUser(body);
        setUser({...props.user, tags: updatedUser.tags, role: updatedUser.role});
        await setTimeout(() => setIsUpdating(false), 2000);
      } catch (err) {
        notifications.error({
            msg:
                err.response && err.response.data
                ? Object.values(err.response.data).join(", ")
                : err.message
        });
        setIsUpdating(false)
      }
    }

  const handleRoleChange = async (role) => {
    const updatedUser = user;
    updatedUser.role = role;
    setUser(updatedUser);
  }

  const handleUserUpdate = async () => {
    setIsEditing(false);
    await updateUser();
  }

  const handleCancelUserEdit = () => {
    setUser({...props.user});
    setTags([...props.user.tags])
    setShowTags(false);
    setIsEditing(false);
  }

  const addTag = (tag) => {
    if (tag.length > 1) {
      const updatedTags = [...tags];
      updatedTags.push(tag);
      setTags(updatedTags);
      setTagInput('');
    }
  }
  
  const deleteTag = (tag) => {
    const updatedTags = [...tags];
    const index = updatedTags.indexOf(tag);
    if (index !== -1) {
      updatedTags.splice(index, 1);
      setTags(updatedTags);
    }
  }


  return (
    <div className="user-container">
      
      <div className="image-container">
        <div className="image">
          <img src={imgSrc} alt="content-img" />
        </div>
      </div>

      <div className="data-container">
        
        <div className="data-row">
          <div className={classnames({"user-item": true, "first": true })}>
              <span>{user.email}</span>
          </div>
          <div className="user-item">
              <span>{user.username}</span>
          </div>
          <div className="user-item">
              <span>{user.fullname}</span>
          </div>

          <div className="role-item">
              {isEditing ?
                  <RolePicker currentRole={user.role} onRoleChange={handleRoleChange}/>
                  :
                  <p>{user.role}</p>
              }
          </div>
          
          <div className={classnames({"user-item": true, "last": true})} >
              <div onClick={() => setShowTags(!showTags)} className={"pressable"}>
                <span>tags</span>
                <img
                  className={classnames({ "tag-icon": true, "arrow-up": showTags })}
                  src={arrow}
                  alt="arrow"
                />
              </div>
          </div>
        </div>
        
        <div className="tags-row">

          <SlideDown closed={!showTags}>
              <div className="tags-content">
                <p className="tag-items">
                {tags && tags.length > 0 ? 
                  tags.map(tag =>
                    <TagButton className="tag-button" key={tag} handleClick={() => deleteTag(tag)} editable={isEditing}>{tag}</TagButton>
                  )
                    :
                    <p className="no-tags">no tags</p>
                }
                </p>
                <SlideDown closed={!isEditing}>
                    <div className="tag-input">
                      <Input
                        onChange={event => setTagInput(event.target.value)}
                        value={tagInput}
                      />
                      <Button handleClick={() => addTag(tagInput)} className="search-button">
                        Add Tag
                      </Button>
                    </div>
                </SlideDown>
              </div>
            </SlideDown>
        </div>

      </div>

      <div className="buttons-container">
        { isUpdating ? 
          <RingLoader height={25} size={25} color={"#f1d19e"} />
        :
          (isEditing ?
            <>
                <img
                    src={tickIcon}
                    alt="save-img"
                    className="save-btn"
                    onClick={() => handleUserUpdate()}
                />
                <img
                    src={closeIcon}
                    alt="close-img"
                    className="close-btn"
                    onClick={() => handleCancelUserEdit()}
                />
            </>
            :
            <img
                src={editIcon}
                alt="edit-img"
                className="edit-btn"
                onClick={() => ( setIsEditing(true), setShowTags(true) )}
            />
          )
        }
      </div>
    </div>
  );
};



export default UsersListItem;
