import "./index.scss";
import { Link, withRouter } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import AppItem from "../AppItem";

const AppRowList = ({ name, apps, draft, history }) => {
  const redirect = path => {
    history.push(path);
  };
  const userRole = useSelector(state => state.auth.currentUser.role);
  return (
    <div>
      <h1>{name}</h1>
      <div className="app-row">
        {apps.map(app => {
          return (
            <AppItem
              key={app.id}
              id={app.id}
              name={app.name}
              date={app.updated_at}
              thumbnail={app.splash_screen ? app.splash_screen : ""}
              draft={draft}
              onClick={redirect}
              userRole={userRole}
            />
          );
        })}
        {draft && userRole === "admin" && (
          <Link to="/create-new-app">
            <div className="app-item addApp" />
          </Link>
        )}
        <div className="app-item placeholder" />
        <div className="app-item placeholder" />
        <div className="app-item placeholder" />
      </div>
    </div>
  );
};

export default withRouter(AppRowList);
