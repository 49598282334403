/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { SlideDown } from "react-slidedown";

import Input from "../../common/Input";
import InputImg from "../../common/InputImg";
import CheckboxImg from "../../common/CheckboxImg";
// import Switcher from "../../Switcher";

import arrow from "../../../assets/images/arrow-down.svg";
import removePageIcon from "../../../assets/images/delete.svg";
import editIcon from "../../../assets/images/settings-1365.svg";
import checkIcon from "../../../assets/images/icon.svg";

import checkboxsImgData from "../../../constants/LayoutConttentFeed";
import applicationActions from "../../../redux/actions/application";

// import pageModules from "../../../constants/PageModules";

import pageService from "../../../services/page";
import notifications from "../../../utils/notifications";

import "react-slidedown/lib/slidedown.css";
import "./index.scss";

const AppPagesListItem = ({
  page,
  draftApp,
  qrData,
  updateDraftApp,
  updateQrData,
  checkIsOpen
}) => {
  const [isPageOpen, setPageOpen] = useState(false);
  const [isEditPageName, setEditPageName] = useState(false);

  useEffect(() => {
    checkIsOpen(isPageOpen);
  }, [isPageOpen]);

  const updateDraftAppPage = async data => {
    try {
      const updatedPage = await pageService.updatePage(data, page.id);

      const { pages } = draftApp;

      const index = pages.findIndex(p => p.id === page.id);

      pages[index] = updatedPage;

      updateDraftApp({
        ...draftApp,
        pages
      });
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };

  const addPageImg = async file => {
    await updateDraftAppPage({ image: file });
  };

  // const switchPremium = async checked => {
  //   await updateDraftAppPage({ is_premium: checked });
  // };

  const addLayout = async value => {
    qrData.pages.push(page);

    const pageIndex = qrData.pages.findIndex(p => p.id === page.id);

    qrData.pages[pageIndex].layout = value;

    updateQrData({ ...qrData });
    await updateDraftAppPage({ layout: value });
  };

  // const addModule = async module => {
  //   if (page.module === module) {
  //     return;
  //   }
  //   await updateDraftAppPage({ module });
  // };

  const removePage = async () => {
    try {
      const { pages } = draftApp;

      await pageService.deletePage(page.id);

      const index = pages.findIndex(p => p.id === page.id);

      pages.splice(index, 1);

      updateDraftApp({
        ...draftApp,
        pages
      });
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };

  const changePageName = event => {
    const { pages } = draftApp;

    const foundPage = pages.find(p => p.id === page.id);

    foundPage.name = event.target.value;

    updateDraftApp({ ...draftApp, pages });
  };

  return (
    <div className="page-container">
      <div className="page">
        <div className="page-content">
          <div className="page-header">
            <div
              className="page-title-dropdown"
              onClick={() => !isEditPageName && setPageOpen(!isPageOpen)}
            >
              {isEditPageName ? (
                <Input
                  onChange={event => changePageName(event)}
                  value={page.name}
                />
              ) : (
                <>
                  <span className="page-name">{page.name}</span>
                  <img
                    className={classnames({ "arrow-up": isPageOpen })}
                    src={arrow}
                    alt="arrow"
                  />
                </>
              )}
            </div>
            {!isPageOpen && (
              <>
                {isEditPageName ? (
                  <img
                    src={checkIcon}
                    className="save-page-name"
                    onClick={() => {
                      updateDraftAppPage(page);
                      setEditPageName(false);
                    }}
                    alt=""
                  />
                ) : (
                  <div className="page-icons">
                    <img
                      src={removePageIcon}
                      onClick={removePage}
                      className="delete-page"
                      alt="delete-icon"
                    />
                    <img
                      src={editIcon}
                      alt=""
                      onClick={() => setEditPageName(true)}
                    />
                  </div>
                )}
              </>
            )}
          </div>

          <SlideDown closed={!isPageOpen}>
            <div className="page-configurations">
              <div className="layouts">
                <form className="layouts-form">
                  {checkboxsImgData.map(inputImgData => (
                    <CheckboxImg
                      key={inputImgData.name}
                      name={page.id}
                      img={inputImgData.img}
                      imgClass="layout-img"
                      checked={page.layout === inputImgData.value}
                      value={inputImgData.value}
                      onChange={() => addLayout(inputImgData.value, page.id)}
                    />
                  ))}
                </form>
              </div>
              {/* <p className="app-page-hint">
                You can assign a module to this subpage. If you don’t select any
                module, you can share any kind of content here.
              </p>
              <div className="premium-switch">
                <span className="premium-switch-title">Premium</span>
                <Switcher
                  onChange={event => switchPremium(event.target.checked)}
                  checked={page.is_premium}
                />
              </div>
              <div className="page-content-settings">
                {pageModules.map(module => (
                  <div
                    key={module}
                    onClick={() => addModule(module)}
                    className={classnames({
                      "page-content-item": true,
                      selected: page.module === module
                    })}
                  >
                    <span className="item-title">{module}</span>
                  </div>
                ))}
              </div> */}
            </div>
          </SlideDown>
        </div>
      </div>
      <InputImg
        onChange={file => addPageImg(file)}
        withoutName
        name={page.id}
        value={page.image}
      />
    </div>
  );
};

const mapStateToProps = ({ application: { draftApp, qrData } }) => ({
  draftApp,
  qrData
});

const mapDispatchToProps = dispatch => ({
  updateDraftApp: data => dispatch(applicationActions.updateDraftApp(data)),
  updateQrData: data => dispatch(applicationActions.updateQrData(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(AppPagesListItem);
