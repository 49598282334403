import React, { useState, useCallback, useRef, useEffect } from "react";
import moment from "moment";
import Calendar from "react-calendar";
import { useParams } from "react-router-dom";

import Input from "../../../components/common/Input";
import Textarea from "../../../components/common/Textarea";
import Button from "../../../components/common/Button";
import AccordionItem from "../../../components/Accordion/AccordionItem";
import InputImg from "../../../components/common/InputImg";
import ContentPopup from "../../../components/ContentPopup";
import promoService from "../../../services/promo";

import useOnClickOutside from "../../../hooks/useClickOutside";
import notifications from "../../../utils/notifications";

import "./index.scss";

const configurations = [
  { title: "Premium", value: "is_premium" },
  { title: "Notification", value: "send_notification" }
];

const Promo = () => {
  const [inProgress, setInProgress] = useState(false);
  const [isShowCalendar, setShowCalendar] = useState(false);
  const [promo, setPromo] = useState({
    title: "",
    description: "",
    is_premium: false,
    send_notification: false,
    picture_url: null,
    expired_at: "",
    url: "",
    active: false,
    notification_body: "",
    notification_title: ""
  });
  const [isShowPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("The banner is created ");

  const { id } = useParams();

  const calendarRef = useRef();

  useOnClickOutside(calendarRef, () => {
    setShowCalendar(false);
  });

  const getPromo = useCallback(async () => {
    try {
      const res = await promoService.getPromos(id);
      if (!res.send_notification) {
        setPromo({
          ...res,
          notification_title: "",
          notification_body: ""
        });
      } else {
        setPromo(res);
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [id]);

  useEffect(() => {
    getPromo();
  }, []);

  useEffect(() => {
    setShowCalendar(false);
  }, [promo.expired_at]);

  const handleSumbit = useCallback(async () => {
    setInProgress(true);
    const message = promo.active
      ? "The banner is updated"
      : "The banner is created";

    try {
      const res = await promoService.updatePromo(id, {
        ...promo,
        active: true
      });
      setPopupMessage(message);
      setShowPopup(true);
      setPromo(res);
    } catch (error) {
      notifications.error({
        msg:
          error.response && error.response.data
            ? Object.values(error.response.data).join(", ")
            : error.message
      });
    }
    setInProgress(false);
  }, [promo]);

  const handleDeactivate = useCallback(async () => {
    setInProgress(true);
    try {
      const res = await promoService.updatePromo(id, { active: false });
      setPopupMessage("The banner is deactivated");
      setShowPopup(true);
      setPromo(res);
    } catch (error) {
      notifications.error({
        msg:
          error.response && error.response.data
            ? Object.values(error.response.data).join(", ")
            : error.message
      });
    }
    setInProgress(false);
  }, []);

  return (
    <div className="promo-container">
      {isShowPopup && (
        <ContentPopup
          UplodingData={{ success: isShowPopup }}
          onExit={() => setShowPopup(false)}
          setIsOpenPopup={setShowPopup}
          goback={() => setShowPopup(false)}
          msg={popupMessage}
        />
      )}
      <p className="promo-header">Create banner</p>
      <div className="promo-info-container">
        <div className="promo-content">
          <p className="info-paragraph">CONTENT</p>
          <div className="info-container">
            <p className="promo-field-title">Header text:</p>
            <p className="tooltip">
              Title at the top of promo box, 10-20 characters.
            </p>
            <Input
              type="text"
              onChange={e => setPromo({ ...promo, title: e.target.value })}
              value={promo.title}
            />
          </div>
          <div className="info-container">
            <p className="promo-field-title">Description:</p>
            <p className="tooltip">
              Longer description, roughly 40-50 characters.
            </p>
            <Textarea
              onChange={e =>
                setPromo({ ...promo, description: e.target.value })
              }
              value={promo.description}
            />
          </div>
          <div className="settings">
            <AccordionItem
              title="SETTINGS"
              hint=""
              configurations={configurations}
              onChange={data =>
                setPromo({
                  ...promo,
                  [data.value]: data.event.target.checked
                })
              }
              defaultOpen
              isContent
              checked={item => promo[item.value]}
            />
          </div>
          {promo.send_notification && (
            <div className="notification-container">
              <div className="notification-title">
                <p className="notification-paragraph">Notification title</p>
                <Textarea
                  onChange={event => {
                    setPromo({
                      ...promo,
                      notification_title: event.target.value
                    });
                  }}
                  value={promo.notification_title}
                />
              </div>
              <div className="notification-text">
                <p className="notification-paragraph">Notification text</p>
                <Textarea
                  onChange={event => {
                    setPromo({
                      ...promo,
                      notification_body: event.target.value
                    });
                  }}
                  value={promo.notification_body}
                />
              </div>
            </div>
          )}
        </div>
        <div className="promo-details">
          <p className="info-paragraph">DETAILS</p>
          <div className="info-container">
            <p className="tooltip">
              You can add an icon to the promotion. File must meet the following
              requirements: 24-bit PNG with transparency, 80*80 px
            </p>
            <InputImg
              onChange={result => setPromo({ ...promo, picture_url: result })}
              value={promo.picture_url !== null && promo.picture_url}
              name="promo-icon"
              placeholder="Icon"
            />
          </div>
          <div className="info-container date">
            <p className="promo-field-title">Expiry date:</p>
            <div onClick={() => setShowCalendar(true)}>
              <Input value={promo.expired_at} />
            </div>
            {isShowCalendar && (
              <div className="react-calendar-container" ref={calendarRef}>
                <Calendar
                  locale="en"
                  className="calendar"
                  onChange={date =>
                    setPromo({
                      ...promo,
                      expired_at: moment(date).format("YYYY-MM-DD")
                    })
                  }
                  minDate={new Date()}
                />
              </div>
            )}
          </div>
          <p className="promo-field-title">URL:</p>
          <Input
            type="text"
            onChange={e => setPromo({ ...promo, url: e.target.value })}
            value={promo.url}
          />
          <p className="promo-field-title">Shopify Product Id:</p>
          <Input
            type="text"
            onChange={e =>
              setPromo({
                ...promo,
                shopify_product_quantity: null,
                shopify_product_id: e.target.value
              })
            }
            value={promo.shopify_product_id}
          />
          {promo.shopify_product_id && promo.shopify_product_quantity && (
            <p className="promo-field-title">
              Shopify Product Quantity: {promo.shopify_product_quantity}
            </p>
          )}
        </div>
      </div>
      <div className="promo-btns-form">
        <Button handleClick={handleSumbit} className="post-btn" type="submit">
          {inProgress ? (
            <div className="lds-ring">
              <div />
              <div />
              <div />
              <div />
            </div>
          ) : (
            <span>{promo.active ? "Update banner" : "Post"}</span>
          )}
        </Button>
        {promo.active && (
          <Button
            handleClick={handleDeactivate}
            className="post-btn"
            type="submit"
          >
            Deactivate
          </Button>
        )}
      </div>
    </div>
  );
};

export default Promo;
