/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
import axios from "axios";
import { objectToFormData } from "object-to-formdata";

const getPromos = async appId => {
  const { data } = await axios.get(`/promos/${appId}/?all=true`);

  return data;
};

const updatePromo = async (appId, data) => {
  if (
    data.hasOwnProperty("picture_url") &&
    typeof data.picture_url === "string" &&
    data.picture_url.substring(0, 4) === "http"
  ) {
    delete data.picture_url;
  }

  console.log("data", data);

  const formData = objectToFormData(data);

  const res = await axios.put(`/promos/${appId}/`, formData);

  return res.data;
};

export { getPromos, updatePromo };

export default {
  getPromos,
  updatePromo
};
