/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React from "react";
import { ResponsivePie } from "@nivo/pie";

const theme = {
  labels: {
    text: {
      fontSize: 15,
      textTransform: "uppercase"
    }
  },
  legends: {
    text: {
      fontSize: 14,
      textTransform: "uppercase"
    }
  }
};

const tooltip = data => {
  return <div className="tooltip-container">{`${data.id}: ${data.value}`}</div>;
};

const Pie = props => {
  const { data } = props;

  const filteredData = [...data].map(item => ({
    ...item,
    label: item.id.toUpperCase()
  }));

  return (
    <ResponsivePie
      {...props}
      enableSlicesLabels={false}
      data={filteredData}
      tooltip={tooltip}
      theme={theme}
    />
  );
};

export default Pie;
