/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import "./index.scss";
import activityService from "../../../services/activity";
import contentService from "../../../services/content";
import PlusIcon from "../../../assets/images/img-plus-biger.svg";
import Loader from "../../../components/Loader";
import Activity from "../../../components/Activity";
import PopularContent from "../../../components/PopularContent";
import notifications from "../../../utils/notifications";

const AppOverview = ({ history }) => {
  const filters = ["Likes", "Comments", "Shares", "Views"];
  const { id } = useParams();

  const [popularContents, setPopularContents] = useState([]);
  const [nextContentsUrl, setNextContentsUrl] = useState("");

  const [activities, setActivities] = useState([]);
  const [nextActivitesUrl, setNextActivitesUrl] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState(filters[0]);

  const redirect = path => {
    if (path) history.push(path);
  };

  const updatePopularContents = async filter => {
    try {
      const resContents = await contentService.getApplicationContents({
        app: id,
        order: `-${filter.toLowerCase()}`
      });
      await setPopularContents(resContents.results);

      setSelectedFilter(filter);
      setNextContentsUrl(resContents.next);
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };

  const loadMoreActivites = async () => {
    try {
      const resActivities = await activityService.getActivites({
        nextUrl: nextActivitesUrl
      });
      await setActivities([...activities, ...resActivities.results]);
      setNextActivitesUrl(resActivities.next);
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };

  const loadMoreContents = async () => {
    try {
      const resContents = await contentService.getApplicationContents({
        nextUrl: nextContentsUrl
      });
      await setPopularContents([...popularContents, ...resContents.results]);
      setNextContentsUrl(resContents.next);
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };

  const fetchActivityAndPopularContents = async () => {
    try {
      const resContents = await contentService.getApplicationContents({
        app: id,
        order: "-likes"
      });
      await setPopularContents(resContents.results);
      setNextContentsUrl(resContents.next);
      const resActivities = await activityService.getActivites({ app: id });
      await setActivities(resActivities.results);
      setNextActivitesUrl(resActivities.next);
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }

    setLoading(false);
  };
  useEffect(() => {
    fetchActivityAndPopularContents(id);
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="app-overview-container">
      <div className="add-content-btn-container">
        <div
          className="add-content-btn"
          onClick={() => {
            redirect(`/dashboard/${id}/contents/create-content`);
          }}
        >
          <img src={PlusIcon} alt="" />
          <p>create new content</p>
        </div>
      </div>
      <div className="activity-contents-wrapper">
        <div className="activity-container">
          <div className="activity-header">
            <p className="title">ACTIVITY</p>
            <InfiniteScroll
              dataLength={activities.length}
              next={loadMoreActivites}
              height="80vh"
              hasMore={nextActivitesUrl !== null}
            >
              {activities.map(activity => (
                <Activity
                  key={activity.id}
                  description={activity.description}
                  createdAt={activity.created_at}
                  username={activity.username}
                />
              ))}
            </InfiniteScroll>
          </div>
        </div>

        <div className="popular-contents-container">
          <div className="popular-contents-header">
            <p className="title">POPULAR CONTENT</p>
            <div className="filters">
              {filters.map(filter => (
                <div className="filter-item" key={filter}>
                  <input
                    type="radio"
                    id={filter}
                    name="content"
                    checked={selectedFilter === filter}
                    onChange={() => updatePopularContents(filter)}
                  />
                  <label htmlFor={filter}>{filter}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="contents-container">
            <InfiniteScroll
              dataLength={popularContents.length}
              next={loadMoreContents}
              height="80vh"
              hasMore={nextContentsUrl !== null}
            >
              {popularContents.map(content => (
                <PopularContent
                  content={content}
                  key={content.id}
                  redirect={() =>
                    redirect(
                      `/dashboard/${id}/contents/edit-content/${content.id}`
                    )
                  }
                />
              ))}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AppOverview);
