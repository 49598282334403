import React, { useState } from "react";

import notifications from "../../utils/notifications";
import contentService from "../../services/content";

import ContentsListItem from "./ContentsListItem";

import "./index.scss";

const ContentsList = ({ items, fetchContents }) => {
  const [selectedContentId, setSelectedContentId] = useState("");
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const handleDelete = id => {
    setSelectedContentId(id);
    setIsOpenPopup(true);
  };

  const removeContent = async () => {
    try {
      await contentService.removeContent(selectedContentId);

      await fetchContents();
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };
  return (
    <div className="contents-list">
      {items.map(content => (
        <ContentsListItem
          isOpenPopup={isOpenPopup}
          setIsOpenPopup={setIsOpenPopup}
          handleDelete={id => handleDelete(id)}
          removeContent={removeContent}
          content={content}
          key={content.id}
        />
      ))}
    </div>
  );
};

export default ContentsList;
