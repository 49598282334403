import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import Switcher from "../../../components/Switcher";
import Input from "../../../components/common/Input";
import AppPagesList from "../../../components/AppPagesList";
import AppTagsList from "../../../components/AppTagsList";

import FirstImgProfile from "../../../assets/images/img-small-8.svg";
import SecondImgProfile from "../../../assets/images/img-small-9.svg";
import FirstProfileMenu from "../../../assets/images/menu-variant-2.svg";
import SecondProfileMenu from "../../../assets/images/menu-variant-1.svg";
import FirstLayoutMenu from "../../../assets/images/img-small-5.svg";
import SecondLayoutMenu from "../../../assets/images/img-small-4.svg";
import LineImg from "../../../assets/images/line-2.png";
import CheckboxImg from "../../../components/common/CheckboxImg";
import PlusImg from "../../../assets/images/img-plus-biger.svg";
import applicationActions from "../../../redux/actions/application";
import LongLine from "../../../assets/images/img-line-long.png";

import pageService from "../../../services/page";
import tagService from "../../../services/tag";

import "./index.scss";

const SetAppNavigation = ({
  updateDraftApp,
  draftApp,
  qrData,
  updateQrData
}) => {
  const [isMenuEnabled, setMenuEnabled] = useState(false);
  const [firstProfileLayout, setFirstProfileLayout] = useState(FirstImgProfile);
  const [secondProfileLayout, setSecondProfileLayout] = useState(
    SecondImgProfile
  );
  const [newPageName, setPageName] = useState("");
  const [newTagDutch, setNewTagDutch] = useState("");
  const [newTagEnglish, setNewTagEnglish] = useState("");
  const [newTagCategory, setTagCategory] = useState("");
  const [newTagCategoryOrder, setTagCategoryOrder] = useState("");

  useEffect(() => {
    updateQrData({
      ...qrData,
      pages: [],
      tags: []
    });
  }, []);

  useEffect(() => {
    if (draftApp.menu_enabled) {
      setFirstProfileLayout(FirstProfileMenu);
      setSecondProfileLayout(SecondProfileMenu);
    } else {
      setFirstProfileLayout(FirstImgProfile);
      setSecondProfileLayout(SecondImgProfile);
    }

    setMenuEnabled(draftApp.menu_enabled);
  }, [draftApp.menu_enabled]);

  const handleSwitch = event => {
    if (!event.target.checked) {
      setFirstProfileLayout(FirstImgProfile);
      setSecondProfileLayout(SecondImgProfile);

      updateDraftApp({
        ...draftApp,
        layout_menu_type: null,
        menu_enabled: event.target.checked
      });

      updateQrData({
        ...qrData,
        layout_menu_type: null,
        menu_enabled: event.target.checked
      });
    } else {
      setFirstProfileLayout(FirstProfileMenu);
      setSecondProfileLayout(SecondProfileMenu);

      updateDraftApp({
        ...draftApp,
        menu_enabled: event.target.checked
      });

      updateQrData({
        ...qrData,
        layout_menu_type: null,
        menu_enabled: event.target.checked
      });
    }

    setMenuEnabled(!isMenuEnabled);
  };

  const addNewPage = async () => {
    if (newPageName === "") {
      return;
    }
    try {
      const page = await pageService.createPage({
        name: newPageName,
        app: draftApp.id,
        order_id: draftApp.pages.length + 1,
        layout: "single"
      });
      draftApp.pages.push(page);
      qrData.pages.push(page);

      updateDraftApp({
        ...draftApp
      });

      updateQrData({
        ...qrData
      });
    } catch (error) {
      return;
    }
    setPageName("");
  };

  const addNewTag = async () => {
    if (newTagDutch === "") {
      return;
    }
    try {
      const tag = await tagService.createTag({
        tag: newTagDutch,
        tag_alt: newTagEnglish,
        category: newTagCategory,
        category_order_id: newTagCategoryOrder,
        app: draftApp.id,
        order_id: draftApp.tags.length + 1
      });
      draftApp.tags.push(tag);
      qrData.tags.push(tag);

      updateDraftApp({
        ...draftApp
      });

      updateQrData({
        ...qrData
      });
    } catch (error) {
      return;
    }
    setNewTagDutch("");
    setNewTagEnglish("");
    setTagCategory("");
    setTagCategoryOrder("");
  };
  return (
    <div className="navigation-container">
      <div className="navigation-header">
        <h4>MENU SETTINGS</h4>
      </div>
      <div className="navigation-content">
        <div className="menu-switcher-container">
          <span className="switcher-title">Menu</span>
          <Switcher
            onChange={event => handleSwitch(event)}
            checked={draftApp.menu_enabled}
          />
        </div>
        {draftApp.menu_layout_enabled && (
          <div>
            <p className="navigation-hint">
              {isMenuEnabled
                ? "Choose a layout for the Profile and Menu buttons! Choose the layout of your menu."
                : "Choose a layout for the Profile button!"}
            </p>
            <div className="layouts-container">
              <div
                className={classnames({
                  "layouts-profile": true,
                  between: isMenuEnabled
                })}
              >
                <form className="profile-btns-form">
                  <CheckboxImg
                    name="profile-img"
                    img={firstProfileLayout}
                    value="right"
                    onChange={() => {
                      updateDraftApp({
                        ...draftApp,
                        menu_position: "right"
                      });
                      updateQrData({
                        ...qrData,
                        menu_position: "right"
                      });
                    }}
                    checked={draftApp.menu_position === "right"}
                  />
                  <CheckboxImg
                    name="profile-img"
                    img={secondProfileLayout}
                    value="left"
                    onChange={() => {
                      updateDraftApp({
                        ...draftApp,
                        menu_position: "left"
                      });
                      updateQrData({
                        ...qrData,
                        menu_position: "left"
                      });
                    }}
                    checked={draftApp.menu_position === "left"}
                  />
                </form>
                {isMenuEnabled && (
                  <>
                    <img src={LineImg} alt="line" />
                    <form className="menu-layouts-form">
                      <CheckboxImg
                        name="layout-menu"
                        img={FirstLayoutMenu}
                        value="horizontal"
                        onChange={() => {
                          updateDraftApp({
                            ...draftApp,
                            layout_menu_type: "horizontal"
                          });
                          updateQrData({
                            ...qrData,
                            layout_menu_type: "horizontal"
                          });
                        }}
                        checked={draftApp.layout_menu_type === "horizontal"}
                      />
                      <CheckboxImg
                        name="layout-menu"
                        img={SecondLayoutMenu}
                        value="vertical"
                        onChange={() => {
                          updateDraftApp({
                            ...draftApp,
                            layout_menu_type: "vertical"
                          });
                          updateQrData({
                            ...qrData,
                            layout_menu_type: "vertical"
                          });
                        }}
                        checked={draftApp.layout_menu_type === "vertical"}
                      />
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {isMenuEnabled && (
          <>
            <p className="navigation-hint">
              Create thematic subpages, or assign a module to create a subpage
              for a specific type of content. Drag them to adjust the order of
              menu items.
              <br />
              Requirements for Menu Image: PNG, vertical or horizontal image
              depending on Menu Layout
            </p>
            <p className="navigation-hint">Application Pages</p>
            <div className="pages-container">
              <div className="add-new-page">
                <div className="input-new-page">
                  <Input
                    placeholder="Name of New Page"
                    type="text"
                    value={newPageName}
                    onChange={event => setPageName(event.target.value)}
                  />
                </div>
                <img
                  className="plus-icon"
                  src={PlusImg}
                  alt="plus-icon"
                  onClick={addNewPage}
                />
              </div>
              <img style={{ marginTop: 48 }} src={LongLine} alt="long-line" />

              {draftApp.pages && !!draftApp.pages.length && (
                <AppPagesList
                  pages={draftApp.pages.sort((a, b) => b.order_id > a.order_id)}
                />
              )}
            </div>
            <p className="navigation-hint">Tags</p>
            <div className="pages-container">
              <div className="add-new-tag">
                <div className="input-new-page">
                  <Input
                    placeholder="Tag (Dutch)"
                    type="text"
                    value={newTagDutch}
                    onChange={event => setNewTagDutch(event.target.value)}
                  />
                  <Input
                    placeholder="Tag (English)"
                    type="text"
                    value={newTagEnglish}
                    onChange={event => setNewTagEnglish(event.target.value)}
                  />
                  <Input
                    placeholder="Category of New Tag"
                    type="text"
                    value={newTagCategory}
                    onChange={event => setTagCategory(event.target.value)}
                  />
                  <Input
                    placeholder="Category Order of New Tag"
                    type="text"
                    value={newTagCategoryOrder}
                    onChange={event => setTagCategoryOrder(event.target.value)}
                  />
                </div>
                <img
                  className="plus-icon"
                  src={PlusImg}
                  alt="plus-icon"
                  onClick={addNewTag}
                />
              </div>
              <img style={{ marginTop: 48 }} src={LongLine} alt="long-line" />

              {draftApp.tags && !!draftApp.tags.length && (
                <AppTagsList
                  tags={draftApp.tags.sort((a, b) => b.order_id > a.order_id)}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ application: { draftApp, qrData } }) => ({
  draftApp,
  qrData
});

const mapDispatchToProps = dispatch => ({
  updateDraftApp: res => dispatch(applicationActions.updateDraftApp(res)),
  updateQrData: data => dispatch(applicationActions.updateQrData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SetAppNavigation);
