import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import likeIcon from "../../assets/images/ic-like.svg";
import messageIcon from "../../assets/images/ic-mess.svg";
import shareIcon from "../../assets/images/share.svg";
import picturePlaceholder from "../../assets/images/img-placeholder@2x.jpg";
import postPlaceholder from "../../assets/images/chat_thumbnail.png";

import "./index.scss";

const PopularContent = props => {
  const { content, redirect, draftApp, selectedFilter } = props;

  let imgSrc = picturePlaceholder;

  if (
    (content.type === "photo" ||
      content.type === "event" ||
      content.type === "music" ||
      content.type === "merchandise") &&
    content.link
  ) {
    imgSrc = content.link;
  } else if (content.type === "video" && content.preview_link) {
    imgSrc = content.preview_link;
  } else if (content.type === "post") {
    if (draftApp.icon_post) {
      imgSrc = draftApp.icon_post;
    } else {
      imgSrc = postPlaceholder;
    }
  }

  let description = "";

  if (content.content_description === "" && content.button_text) {
    description =
      content.button_text.length > 102
        ? `${content.button_text.slice(0, 100)}...`
        : content.button_text;
  } else if (content.content_description) {
    description =
      content.content_description.length > 102
        ? `${content.content_description.slice(0, 100)}...`
        : content.content_description;
  }

  return (
    <div
      className={
        content.type === "archived"
          ? "popular-content-item off-click"
          : "popular-content-item"
      }
      onClick={content.type !== "archived" ? redirect : () => {}}
    >
      <div className="image">
        <img src={imgSrc} alt="" />
      </div>

      <div className="content-data">
        <p className="date">
          {moment
            .utc(content.created_at)
            .local()
            .format("kk:mm, DD.MMM, YYYY")}
        </p>
        <p className="description">{description}</p>
        <div className="horizont-line" />
        <div className="counters">
          <div className="count">
            <img src={likeIcon} alt="like" />
            <span>
              {selectedFilter === "Like"
                ? `${content.count_by_period}/${content.likes_count}`
                : content.likes_count}
            </span>
          </div>

          <div className="count">
            <img src={messageIcon} alt="message" />
            <span>
              {selectedFilter === "Comment"
                ? `${content.count_by_period}/${content.comments_count}`
                : content.comments_count}
            </span>
          </div>

          <div className="count">
            <img src={shareIcon} alt="share" />
            <span>
              {selectedFilter === "Share"
                ? `${content.count_by_period}/${content.shares_count}`
                : content.shares_count}
            </span>
          </div>
        </div>
        <div className="creater">
          <span> {`${content.type} by `}</span>
          <span style={{ fontWeight: "bold" }}>{content.username}</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ application: { draftApp } }) => ({ draftApp });

export default connect(mapStateToProps)(PopularContent);
