/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { connect } from "react-redux";

import Input from "../../common/Input";
import InputImg from "../../common/InputImg";
// import Switcher from "../../Switcher";

import arrow from "../../../assets/images/arrow-down.svg";
import removeTagIcon from "../../../assets/images/delete.svg";
import editIcon from "../../../assets/images/settings-1365.svg";
import checkIcon from "../../../assets/images/icon.svg";

import applicationActions from "../../../redux/actions/application";

import tagService from "../../../services/tag";
import notifications from "../../../utils/notifications";

import "react-slidedown/lib/slidedown.css";
import "./index.scss";

const AppTagsListItem = ({ tag, draftApp, updateDraftApp, checkIsOpen }) => {
  const [isTagOpen, setTagOpen] = useState(false);
  const [isEditTag, setEditTag] = useState(false);

  useEffect(() => {
    checkIsOpen(isTagOpen);
  }, [isTagOpen]);

  const updateDraftAppTag = async data => {
    try {
      const updatedTag = await tagService.updateTag(data, tag.id);

      const { tags } = draftApp;

      const index = tags.findIndex(p => p.id === tag.id);

      tags[index] = updatedTag;

      updateDraftApp({
        ...draftApp,
        tags
      });
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };

  const addTagImg = async file => {
    await updateDraftAppTag({ image: file });
  };

  const removeTag = async () => {
    try {
      const { tags } = draftApp;

      await tagService.deleteTag(tag.id);

      const index = tags.findIndex(p => p.id === tag.id);

      tags.splice(index, 1);

      updateDraftApp({
        ...draftApp,
        tags
      });
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };

  const changeTagDutch = event => {
    const { tags } = draftApp;

    const foundTag = tags.find(p => p.id === tag.id);

    foundTag.tag = event.target.value;

    updateDraftApp({ ...draftApp, tags });
  };

  const changeTagEnglish = event => {
    const { tags } = draftApp;

    const foundTag = tags.find(p => p.id === tag.id);

    foundTag.tag_alt = event.target.value;

    updateDraftApp({ ...draftApp, tags });
  };

  const changeTagCategory = event => {
    const { tags } = draftApp;

    const foundTag = tags.find(p => p.id === tag.id);

    foundTag.category = event.target.value;

    updateDraftApp({ ...draftApp, tags });
  };

  const changeTagCategoryOrderId = event => {
    const { tags } = draftApp;

    const foundTag = tags.find(p => p.id === tag.id);

    foundTag.category_order_id = event.target.value;

    updateDraftApp({ ...draftApp, tags });
  };
  return (
    <div className="page-container">
      <div className="page">
        <div className="page-content">
          <div className="page-header">
            <div
              className="page-title-dropdown"
              onClick={() => !isEditTag && setTagOpen(!isTagOpen)}
            >
              {isEditTag ? (
                <div>
                  <Input
                    onChange={event => changeTagDutch(event)}
                    value={tag.tag}
                    placeholder="Tag (Dutch)"
                  />
                  <Input
                    onChange={event => changeTagEnglish(event)}
                    value={tag.tag_alt}
                    placeholder="Tag (English)"
                  />
                  <Input
                    onChange={event => changeTagCategory(event)}
                    value={tag.category}
                    placeholder="Category"
                  />
                  <Input
                    onChange={event => changeTagCategoryOrderId(event)}
                    value={tag.category_order_id}
                    placeholder="Category Order"
                  />
                </div>
              ) : (
                <>
                  <span className="page-name">
                    {tag.tag} ({tag.tag_alt})
                  </span>
                  <img
                    className={classnames({ "arrow-up": isTagOpen })}
                    src={arrow}
                    alt="arrow"
                  />
                </>
              )}
            </div>
            {!isTagOpen && (
              <>
                {isEditTag ? (
                  <img
                    src={checkIcon}
                    className="save-page-name"
                    onClick={() => {
                      updateDraftAppTag(tag);
                      setEditTag(false);
                    }}
                    alt=""
                  />
                ) : (
                  <div className="page-icons">
                    <img
                      src={removeTagIcon}
                      onClick={removeTag}
                      className="delete-page"
                      alt="delete-icon"
                    />
                    <img
                      src={editIcon}
                      alt=""
                      onClick={() => setEditTag(true)}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <InputImg
        onChange={file => addTagImg(file)}
        withoutName
        name={tag.id}
        value={tag.image}
      />
    </div>
  );
};

const mapStateToProps = ({ application: { draftApp } }) => ({
  draftApp
});

const mapDispatchToProps = dispatch => ({
  updateDraftApp: data => dispatch(applicationActions.updateDraftApp(data)),
  updateQrData: data => dispatch(applicationActions.updateQrData(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(AppTagsListItem);
