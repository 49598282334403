import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";

import PrivateRoute from "../components/PrivateRoute";
import Header from "../components/Header";
import Loader from "../components/Loader";
import CreateApp from "./CreateApp";
import PublishApp from "./PublishApp";
import Dashboard from "./Dashboard";
import AppList from "./AppList";
import Login from "./Login";
import NotificationPopup from "../components/NotificationPopup";

import mainActions from "../redux/actions/main";

import authHelper from "../helpers/auth";
import authActions from "../redux/actions/auth";

import "./App.scss";

const App = ({
  isShowQRCodePopup,
  isBlurred,
  history,
  setCurrentUser,
  setQRCodePopup
}) => {
  const [isLoading, setLoadingStatus] = useState(true);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    authHelper.getCurrentUser({
      history,
      setCurrentUser,
      onSuccess: () => setLoadingStatus(false),
      onFail: () => setLoadingStatus(false)
    });
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div className={classnames({ "app-container": true, blurred: isBlurred })}>
      {isBlurred && (
        <div
          className={
            isShowQRCodePopup
              ? "background-blur hide-header"
              : "background-blur"
          }
        />
      )}

      <Header />

      <div className="main-container">
        {isShowQRCodePopup && (
          <NotificationPopup
            notificationText="You can see your app on your phone. Scan this QR code!"
            buttonText="OKAY"
            isQRCodePopup
            setNotificationPopup={setQRCodePopup}
          />
        )}
        <div className="main-content">
          <Switch>
            <PrivateRoute exact path="/" component={AppList} />
            <PrivateRoute exact path="/create-new-app" component={CreateApp} />
            <PrivateRoute
              exact
              path="/publish-app/:id"
              component={PublishApp}
            />
            <PrivateRoute path="/dashboard/:id" component={Dashboard} />
            <Route exact path="/login" component={Login} />

            <Redirect to="/" />
          </Switch>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ main: { isBlurred, isShowQRCodePopup } }) => ({
  isBlurred,
  isShowQRCodePopup
});

const mapDispatchToProps = dispatch => ({
  setCurrentUser: (...props) => dispatch(authActions.setCurrentUser(...props)),
  setQRCodePopup: isShowQRCodePopup =>
    dispatch(mainActions.setQRCodePopup(isShowQRCodePopup))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
