/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import React, { useState } from "react";
import { connect } from "react-redux";
import { Container, Draggable } from "react-smooth-dnd";

import applicationActions from "../../redux/actions/application";
import tagService from "../../services/tag";

import AppTagsListItem from "./AppTagsListItem";

import "./index.scss";

const AppTagsList = ({ tags, updateDraftApp, draftApp, isCreatingProcess }) => {
  const [isDraggable, setIsDraggable] = useState(true);

  const checkIsOpen = value => {
    if (value) {
      setIsDraggable(false);
    } else {
      setIsDraggable(true);
    }
  };

  const onDrop = async dropResult => {
    const { removedIndex, addedIndex, payload } = dropResult;
    const arr = [...draftApp.tags];

    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];

    let itemToAdd = payload;

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
    result.forEach((item, index) => {
      item.order_id = index + 1;

      if (!isCreatingProcess) {
        tagService.updateTag(item, item.id);
      }
    });

    updateDraftApp({
      ...draftApp,
      tags: [...result]
    });
  };

  return (
    <div className="tags-list">
      <Container
        onDrop={onDrop}
        shouldAcceptDrop={() => isDraggable}
        dropPlaceholder={{
          animationDuration: 150,
          showOnTop: true,
          className: "drop-preview"
        }}
        dropPlaceholderAnimationDuration={200}
      >
        {tags.map(tag => (
          <Draggable key={tag.id}>
            <AppTagsListItem
              tag={tag}
              checkIsOpen={value => checkIsOpen(value)}
            />
          </Draggable>
        ))}
      </Container>
    </div>
  );
};
const mapStateToProps = ({ application: { draftApp, isCreatingProcess } }) => ({
  draftApp,
  isCreatingProcess
});

const mapDispatchToProps = dispatch => ({
  updateDraftApp: res => dispatch(applicationActions.updateDraftApp(res))
});

export default connect(mapStateToProps, mapDispatchToProps)(AppTagsList);
