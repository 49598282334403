/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
import axios from "axios";
import { objectToFormData } from "object-to-formdata";

const createPage = async data => {
  const res = await axios.post("/application-pages/", data);

  return res.data;
};

const updatePage = async (data, id) => {
  if (
    data.hasOwnProperty("image") &&
    typeof data.image === "string" &&
    data.image.substring(0, 4) === "http"
  ) {
    delete data.image;
  }
  if (data.hasOwnProperty("module")) {
    delete data.module;
  }
  const formData = objectToFormData(data);

  const res = await axios.patch(`/application-pages/${id}/`, formData);

  return res.data;
};

const deletePage = async id => {
  const res = await axios.delete(`/application-pages/${id}/`);

  return res.data;
};

export { createPage, updatePage, deletePage };

export default {
  createPage,
  updatePage,
  deletePage
};
