/* eslint-disable import/no-named-as-default-member */
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import notifications from "../../utils/notifications";
import Button from "../../components/common/Button";
import QRCodeImg from "../../components/common/QRCode";

import SetAppLogo from "./SetAppLogo";
import SetAppTemplate from "./SetAppTemplate";
import SetAppDesign from "./SetAppDesign";
import SetAppBehavior from "./SetAppBehavior";
import SetAppNavigation from "./SetAppNavigation";

import appLogo from "../../assets/images/settings.svg";
import backArrow from "../../assets/images/arrow_left.svg";
import galleryIcon from "../../assets/images/gallery-grid.svg";
import eyeIcon from "../../assets/images/eye.svg";
import pictureIcon from "../../assets/images/gallery.svg";
import menuIcon from "../../assets/images/menu.svg";

import applicationService from "../../services/application";
import applicationActions from "../../redux/actions/application";
import mainActions from "../../redux/actions/main";

import "./index.scss";

const STEPS = [
  { number: 1, icon: appLogo, title: "Create a new app" },
  { number: 2, icon: galleryIcon, title: "Choose your template" },
  { number: 3, icon: eyeIcon, title: "Customize your design" },
  { number: 4, icon: pictureIcon, title: "Default behavior" },
  { number: 5, icon: menuIcon, title: "Build your navigation" }
];

const CreateApp = ({
  draftApp,
  qrData,
  updateDraftApp,
  setQRCodePopup,
  isShowQRCodePopup,
  history,
  setDraftEmpty,
  setCreateProcess,
  setServerDraftApp,
  setIsDisabledNext,
  isDisabledNext,
  currentUser,
  updateQrData
}) => {
  const [activeStep, setActiveStep] = useState(1);
  const [stepTitle, setStepTitle] = useState("Create a new app");
  const [nameInputError, setNameInputError] = useState(false);
  const [urlInputError, setUrlInputError] = useState(false);

  useEffect(() => {
    setCreateProcess(true);
  }, []);

  useEffect(() => {
    const index = STEPS.findIndex(step => step.number === activeStep);

    setStepTitle(STEPS[index].title);
  }, [activeStep]);

  const handleNextStep = async () => {
    setIsDisabledNext(true);
    if (activeStep === 1) {
      if (!draftApp.name || !draftApp.url_identifier) {
        if (!draftApp.name) {
          setNameInputError(true);
        } else {
          setNameInputError(false);
        }
        if (!draftApp.url_identifier) {
          setUrlInputError(true);
        } else {
          setUrlInputError(false);
        }
        return;
      }
    }
    setNameInputError(false);
    setUrlInputError(false);

    const nextStep = activeStep + 1;

    if (nextStep === 6) {
      setActiveStep(1);
      return;
    }

    let res;

    try {
      if (activeStep === 1 && !draftApp.id) {
        res = await applicationService.createApp(draftApp);
      } else {
        res = await applicationService.updateApplication(draftApp);
      }
      updateDraftApp(res);
      setServerDraftApp(res);
      setIsDisabledNext(false);
    } catch (err) {
      setIsDisabledNext(true);
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
      return;
    }

    updateQrData({});
    setActiveStep(nextStep);
  };

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 1:
        return (
          <SetAppLogo
            urlInputError={urlInputError ? "error" : ""}
            nameInputError={nameInputError ? "error" : ""}
          />
        );
      case 2:
        return <SetAppTemplate />;
      case 3:
        return <SetAppDesign />;
      case 4:
        return <SetAppBehavior />;
      case 5:
        return <SetAppNavigation />;
      default:
        return "Unknown stepIndex";
    }
  };

  const handleSave = async () => {
    try {
      await applicationService.updateApplication(draftApp);
      history.push("/");
      setDraftEmpty();
      setCreateProcess(false);
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };

  const handleAddVitals = () => {
    history.push(`/publish-app/${draftApp.id}`);
  };

  const handleBackStep = step => {
    updateQrData({});
    if (step) {
      setActiveStep(step);
      return;
    }
    setActiveStep(activeStep - 1);
  };

  return currentUser.role === "admin" ? (
    <div className="create-app-container">
      <div
        className="create-app-header"
        onClick={() => activeStep > 1 && handleBackStep()}
      >
        {activeStep > 1 && <img src={backArrow} alt="" />}
        <p>{stepTitle}</p>
      </div>
      <div className="create-app-content">
        <div className="stepper">
          <div className="vertical-line" />
          {STEPS.map(step => (
            <div
              key={step.number}
              className={classNames({
                step: true,
                "offset-top": step.number !== 1 && step.number === activeStep,
                "offset-bottom":
                  step.number !== 5 && step.number === activeStep,
                isClickable: step.number < activeStep
              })}
              onClick={() =>
                step.number < activeStep && handleBackStep(step.number)
              }
            >
              <div
                className={classNames({
                  "step-icon": true,
                  active: step.number === activeStep
                })}
              >
                <img src={step.icon} alt={`${step.number}-logo`} />
              </div>
            </div>
          ))}
        </div>
        <div className="step-content">
          {getStepContent(activeStep)}
          {activeStep + 1 === 6 ? (
            <div className="finish-buttons">
              <Button
                handleClick={handleSave}
                className="save-btn"
                type="submit"
              >
                Save as wip
              </Button>
              <Button
                handleClick={handleAddVitals}
                className="add-vitals-btn"
                type="submit"
              >
                Add app vitals
              </Button>
            </div>
          ) : (
            <Button
              handleClick={handleNextStep}
              className="next-btn"
              type="submit"
              disabled={isDisabledNext}
            >
              Next
            </Button>
          )}
        </div>
        <div
          className={activeStep < 3 ? "qr-code" : "qr-code show"}
          onClick={() => {
            setQRCodePopup(!isShowQRCodePopup);
          }}
        >
          <QRCodeImg
            value={JSON.stringify({
              id: draftApp.id,
              ...qrData
            })}
            size="116"
          />
        </div>
      </div>
    </div>
  ) : (
    <Redirect to="/" />
  );
};

const mapStateToProps = ({
  application: { draftApp, qrData, isDisabledNext },
  main: { isShowQRCodePopup },
  auth: { currentUser }
}) => ({ draftApp, qrData, isDisabledNext, isShowQRCodePopup, currentUser });

const mapDispatchToProps = dispatch => ({
  updateDraftApp: res => dispatch(applicationActions.updateDraftApp(res)),
  setQRCodePopup: isShowQRCodePopup =>
    dispatch(mainActions.setQRCodePopup(isShowQRCodePopup)),
  setDraftEmpty: () => dispatch(applicationActions.setDraftEmpty()),
  setCreateProcess: isCreatingProcess =>
    dispatch(applicationActions.setCreateProcess(isCreatingProcess)),
  setServerDraftApp: serverDraftApp =>
    dispatch(applicationActions.setServerDraftApp(serverDraftApp)),
  setIsDisabledNext: isDisabledNext =>
    dispatch(applicationActions.setIsDisabledNext(isDisabledNext)),
  updateQrData: data => dispatch(applicationActions.updateQrData(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateApp);
