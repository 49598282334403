import React, { useState } from "react";
import { SketchPicker } from "react-color";

import Popup from "../../Popup";

import questionImg from "../../../assets/images/questionmark.svg";
import colorAImgs from "../../../assets/images/image1.svg";
import colorBImgs from "../../../assets/images/image2.svg";
import highlightImgs from "../../../assets/images/image3.svg";
import overlayImgs from "../../../assets/images/image5.svg";
import postBubbleImgs from "../../../assets/images/image6.svg";

import "./index.scss";

const ColorPicker = ({ onChange, name, defaultcolor }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [selectedColor, setColor] = useState(defaultcolor);
  const [isShowInfoPopup, setShowInfoPopup] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = color => {
    onChange(color.hex);
    setColor(color.hex);
  };

  let imgs;
  let popupText;

  switch (name) {
    case "Color A":
      imgs = colorAImgs;
      popupText =
        "This is your main background color in the content feed, profile page and content details page. Also this is the color of incoming messages.";
      break;
    case "Color B":
      imgs = colorBImgs;
      popupText =
        "This is your main background color of the onboarding flow, settings and the chat page. Also this is the color of the comments.";
      break;
    case "Highlight":
      imgs = highlightImgs;
      popupText =
        "This is the color of buttons, active icons and your messages.";
      break;
    case "Overlay":
      imgs = overlayImgs;
      popupText =
        "This is a semitransparent layer on the top bar, menu items, and splash image.";
      break;
    case "Post Bubble":
      imgs = postBubbleImgs;
      popupText =
        "This is the background color of your posts. Keep in mind that this color should have high contrast with the text color!";
      break;
    case "Text and Icons":
      popupText =
        "This is the color of every text and icon in your app. It is important to choose a color with good contrast!";
      break;
    default:
      break;
  }

  return (
    <div>
      {isShowInfoPopup && (
        <Popup
          withBtns={false}
          withImgs
          withText
          imgs={imgs}
          popupText={popupText}
          setLocalPopup={setShowInfoPopup}
          question={name}
        />
      )}
      <div className="color-picker-container">
        <div
          className="color-container"
          onClick={handleClick}
          style={{ backgroundColor: selectedColor }}
        />

        <div className="color-text-container">
          {name !== "Call to action" && (
            <img
              src={questionImg}
              onClick={() => setShowInfoPopup(true)}
              alt=""
            />
          )}
          <p className="color-text">{selectedColor}</p>
        </div>
      </div>
      <div>
        <p className="color-picker-footer">{name}</p>
      </div>
      {displayColorPicker ? (
        <div className="color-picker-popover">
          <div className="color-picker-cover" onClick={handleClose} />
          <SketchPicker color={selectedColor} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
