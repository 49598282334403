/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-const */
/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useState } from "react";
import { Switch, Route, useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditApp from "./EditApp";
import Promo from "./Promo";
import Contens from "./Contents";
import AppOverview from "./AppOverview";
import Users from "./Users";
import StatsData from "./StatsData";
import Menu from "../../components/common/Menu";
import Loader from "../../components/Loader";
import Popup from "../../components/Popup";

import applicationService from "../../services/application";
import applicationActions from "../../redux/actions/application";
import mainActions from "../../redux/actions/main";
import notifications from "../../utils/notifications";

import "./index.scss";

const Dashboard = ({
  updateDraftApp,
  setServerDraftApp,
  isContentInProgress,
  history,
  setContentInProgress,
  currentUser
}) => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [redirectPath, setRedirectPath] = useState("/");

  const fetchApplication = async () => {
    try {
      const res = await applicationService.getApplication(id);
      await updateDraftApp(res);
      setServerDraftApp(res);
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }

    setLoading(false);
  };
  useEffect(() => {
    fetchApplication(id);
  }, []);

  let tabs = [
    { name: "APP OVERVIEW", url: `/dashboard/${id}/app-overview/` },
    { name: "CONTENT", url: `/dashboard/${id}/contents/` },
    { name: "PROMO", url: `/dashboard/${id}/promo/` },
    { name: "EDIT APP", url: `/dashboard/${id}/edit-app/` },
    { name: "USERS", url: `/dashboard/${id}/users/` },
    { name: "STATS & DATA", url: `/dashboard/${id}/stats-n-data/` }
  ];
  if (currentUser.role === "creator2") {
    tabs.splice(-2, 2);
  }
  if (currentUser.role === "creator1") {
    tabs.splice(-2, 1);
  }
  if (currentUser.role !== "admin") {
    tabs.splice(4, 1);
  }

  return isLoading ? (
    <Loader />
  ) : (
    <div className="dashboard-container">
      <div className="dashboard-content">
        {isOpenPopup && (
          <Popup
            setLocalPopup={setIsOpenPopup}
            onResolve={() => {
              setIsOpenPopup(false);
            }}
            question="Do you want to save your progress?"
            onReject={() => {
              setContentInProgress(false);
              setIsOpenPopup(false);
              history.push(redirectPath);
            }}
            onResolveTitle="YES"
            onRejectTitle="NO"
            withoutActions
          />
        )}
        <Menu
          tabs={tabs}
          setisopenpopup={setIsOpenPopup}
          iscontentinprogress={isContentInProgress}
          setredirectpath={setRedirectPath}
        />
        <Switch>
          <Route path="/dashboard/:id/app-overview/" component={AppOverview} />
          <Route path="/dashboard/:id/contents/" component={Contens} />
          <Route path="/dashboard/:id/promo/" component={Promo} />
          <Route path="/dashboard/:id/edit-app/" component={EditApp} />
          <Route path="/dashboard/:id/users/" component={Users} />
          <Route path="/dashboard/:id/stats-n-data/" component={StatsData} />
        </Switch>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  main: { isContentInProgress },
  auth: { currentUser }
}) => ({
  isContentInProgress,
  currentUser
});

const mapDispatchToProps = dispatch => ({
  updateDraftApp: res => dispatch(applicationActions.updateDraftApp(res)),
  setServerDraftApp: serverDraftApp =>
    dispatch(applicationActions.setServerDraftApp(serverDraftApp)),
  setContentInProgress: isContentInProgress =>
    dispatch(mainActions.setContentInProgress(isContentInProgress))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
