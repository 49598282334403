/* eslint-disable react/no-unescaped-entities */
/* eslint-disable radix */
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import Calendar from "react-calendar";
import { CSVLink } from "react-csv";
import BarLoader from "react-spinners/BarLoader";

import Filters from "../../../components/Filters";
import PopularContent from "../../../components/PopularContent";
import Button from "../../../components/common/Button";
import Line from "./Line";
import Pie from "./Pie";

import notifications from "../../../utils/notifications";
import applicationService from "../../../services/application";

import "react-calendar/dist/Calendar.css";
import "./index.scss";

const filterItems = [
  "Last 7 days",
  "Last 30 days",
  "Last 90 days",
  "Last 12 months"
];

const filters = ["Like", "Comment", "Share", "View"];

const StatsData = () => {
  const [selectedTimeframe, setSelectedTimeframe] = useState("Last 7 days");
  const [fromDateCalendar, setFromDateCalendar] = useState(null);
  const [toDateCalendar, setToDateCalendar] = useState(null);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [contents, setContents] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(filters[0]);
  const [isShowCalendar, setShowCalendar] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pointSize, setPointSize] = useState(10);
  const [tickValues, setTickValues] = useState("every day");
  const [isShowDate, setShowDate] = useState(false);

  const { id } = useParams();

  const csvRef = useRef();

  const getAnalytics = async dateValue => {
    let fromDate;
    let toDate;

    setLoading(true);

    if (fromDateCalendar !== null && toDateCalendar !== null) {
      fromDate = moment(fromDateCalendar).format("YYYY-MM-DD");
      toDate = moment(toDateCalendar).format("YYYY-MM-DD");
    } else {
      fromDate = moment()
        .subtract(parseInt(dateValue[0]), dateValue[1])
        .format("YYYY-MM-DD");
      toDate = moment()
        .subtract(2, "d")
        .format("YYYY-MM-DD");
    }

    try {
      const res = await applicationService.getAnalytics({
        fromDate,
        toDate,
        id
      });
      const contentStr = `content_by_${selectedFilter.toLowerCase()}`;

      setAnalyticsData(res);
      setContents(res[contentStr]);
      setLoading(false);
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };

  useEffect(() => {
    setPointSize(10);
    setTickValues("every day");
    setShowDate(false);

    if (selectedTimeframe === "Custom range") {
      setShowCalendar(true);
      setPointSize(3);
      setTickValues("every 1 month");
      setShowDate(true);
    } else {
      setShowCalendar(false);

      let dateValue;

      if (selectedTimeframe === "Last 7 days") {
        dateValue = ["6", "d"];
      } else if (selectedTimeframe === "Last 30 days") {
        dateValue = ["30", "d"];
      } else if (selectedTimeframe === "Last 90 days") {
        dateValue = ["90", "d"];
        setPointSize(3);
        setTickValues("every week");
        setShowDate(true);
      } else if (selectedTimeframe === "Last 12 months") {
        dateValue = ["12", "months"];
        setPointSize(3);
        setTickValues("every 1 month");
        setShowDate(true);
      }

      getAnalytics(dateValue);
    }
  }, [selectedTimeframe]);

  useEffect(() => {
    if (fromDateCalendar !== null && toDateCalendar !== null) {
      getAnalytics();
      setShowCalendar(false);
    }
  }, [fromDateCalendar, toDateCalendar]);

  const redirect = link => {
    window.location.href = link;
  };

  const setFilter = filter => {
    if (filter !== selectedFilter) {
      setSelectedFilter(filter);
      const contentStr = `content_by_${filter.toLowerCase()}`;

      setContents(analyticsData[contentStr]);
    }
  };

  const getCsv = async () => {
    setCsvData([]);

    try {
      const res = await applicationService.getUsersCsv(id);
      setCsvData(res);
      csvRef.current.link.click();
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };

  return (
    <div className="stats-n-data-container">
      <div className="stats-data-header">
        <div className="timeframe">
          <Filters
            items={filterItems}
            onSelectItem={item => {
              setFromDateCalendar(null);
              setToDateCalendar(null);
              setSelectedTimeframe(item);
            }}
            isStatistic
            onSelectCalendar={setShowCalendar}
          />
          {fromDateCalendar !== null && toDateCalendar !== null && (
            <p className="selected-range">{`${moment(fromDateCalendar).format(
              "DD MMMM YYYY"
            )} - ${moment(toDateCalendar).format("DD MMMM YYYY")}`}</p>
          )}
          <p className="timeframe-placeholder">
            Change selection to view different timeslots!
          </p>
        </div>
        {!loading && (
          <div className="active-users-container">
            <p>{`Active users now: ${analyticsData.active_users}`}</p>
          </div>
        )}
      </div>
      {loading ? (
        <>
          <BarLoader
            size={150}
            color="rgba(241, 209, 158, 0.7)"
            loading={loading}
          />
        </>
      ) : (
        <>
          {isShowCalendar && (
            <div className="react-calendar-container">
              <Calendar
                locale="en"
                className="calendar"
                onChange={date => setFromDateCalendar(date)}
                value={fromDateCalendar}
              />
              <Calendar
                locale="en"
                className="calendar"
                onChange={date => setToDateCalendar(date)}
                value={toDateCalendar}
                maxDate={new Date(moment().subtract(2, "d"))}
              />
            </div>
          )}
          <div className="overview-container">
            <p className="overview-title">Overview</p>
            <div className="overview-content">
              <span>{`New users: ${analyticsData.new_user_count}`}</span>
              <span>{`Users: ${analyticsData.total_user_count}`}</span>
            </div>
          </div>
          <div className="overview-container">
            <p className="overview-title">Estimated Earned</p>
            <div className="overview-content">
              <span>
                {`Estimated earning based on the number of subscribed users on ${
                  analyticsData.user_count[0].data[
                    analyticsData.user_count[0].data.length - 1
                  ].x
                }: ${analyticsData.total_earnings} euro p/m`}
              </span>
            </div>
            <ul style={{ color: "#ffffff" }}>
              <li>
                Sales estimation does not take into account: store fees, trial
                periods, promo offers, etc.
              </li>
            </ul>
          </div>
          <div className="users-count-line-container">
            <p className="users-count-title">Users count</p>
            <Line
              data={analyticsData.user_count}
              pointSize={pointSize}
              tickValues={tickValues}
              isShowDate={isShowDate}
            />

            <ul style={{ color: "#ffffff" }}>
              <li>
                Data for the last 2-3 days might be incomplete or not yet
                available.
              </li>
              <li>
                {" "}
                Active subscriptions include free trial periods, introductory
                offers, cancellactions that are allowed to finish the last term
                (grace period), free promotional offers, and people for whom
                payment failed but are allowed to keep an active subscription
                while billing is retried.
              </li>
              <li>
                This plot is only available for whitelisted apps published under
                CelApp's own store accounts. To include other apps, or to
                support more developer accounts, please contact CelApp.
              </li>
            </ul>

            <Button className="upload-users-btn" handleClick={getCsv}>
              Export user data
            </Button>
            <CSVLink ref={csvRef} data={csvData} filename="users.csv" />
          </div>
          <div className="content-performance-container">
            <div className="content-performance-header">
              <p className="title">Content Performance</p>
              <div className="filters">
                {filters.map(filter => (
                  <div className="filter-item" key={filter}>
                    <input
                      type="radio"
                      id={filter}
                      name="content"
                      checked={selectedFilter === filter}
                      onChange={() => setFilter(filter)}
                    />
                    <label htmlFor={filter}>{filter}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="contents-container">
              {contents.length ? (
                contents.map(content => (
                  <PopularContent
                    selectedFilter={selectedFilter}
                    content={content}
                    key={content.id}
                  />
                ))
              ) : (
                <p className="empty-content-placeholder">
                  No data available for the selected time range.
                </p>
              )}
            </div>
          </div>
          <div className="gender-distribution-container">
            <p className="gender-distribution-title">Gender Distribution</p>
            {analyticsData.by_gender.length ? (
              <Pie
                data={analyticsData.by_gender}
                margin={{ top: 100, right: 100, bottom: 80, left: -30 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: "yellow_orange_brown" }}
                borderWidth={1}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                radialLabel={e => `${e.id}: ${e.value}`}
                radialLabelsSkipAngle={10}
                radialLabelsTextXOffset={5}
                radialLabelsTextColor="#f1d19e"
                radialLabelsLinkOffset={0}
                radialLabelsLinkDiagonalLength={16}
                radialLabelsLinkHorizontalLength={24}
                radialLabelsLinkStrokeWidth={0}
                radialLabelsLinkColor={{ from: "color" }}
                animate
                motionStiffness={90}
                motionDamping={15}
                legends={[
                  {
                    anchor: "right",
                    direction: "column",
                    translateY: 0,
                    itemWidth: 120,
                    itemsSpacing: 10,
                    itemHeight: 18,
                    itemTextColor: "#999",
                    symbolSize: 18,
                    symbolShape: "circle",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#000"
                        }
                      }
                    ]
                  }
                ]}
              />
            ) : (
              <div className="empty-pie-container">
                <p className="empty-content-placeholder">
                  No data available for the selected time range.
                </p>
              </div>
            )}
          </div>
          <div className="demographic-container">
            <p className="demographic-title">Demographic</p>
            {analyticsData.by_country.length ? (
              <Pie
                data={analyticsData.by_country}
                margin={{ top: 100, right: 100, bottom: 80, left: -30 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: "yellow_orange_brown" }}
                borderWidth={1}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                radialLabel={e => `${e.id}: ${e.value}`}
                radialLabelsSkipAngle={15}
                radialLabelsTextXOffset={10}
                radialLabelsTextColor="#f1d19e"
                radialLabelsLinkOffset={10}
                radialLabelsLinkDiagonalLength={4}
                radialLabelsLinkHorizontalLength={5}
                radialLabelsLinkStrokeWidth={0}
                radialLabelsLinkColor={{ from: "color" }}
                animate
                motionStiffness={90}
                motionDamping={15}
                legends={[
                  {
                    anchor: "right",
                    direction: "column",
                    translateY: 0,
                    itemWidth: 120,
                    itemsSpacing: 5,
                    itemHeight: 18,
                    itemTextColor: "#999",
                    symbolSize: 18,
                    symbolShape: "circle"
                  }
                ]}
              />
            ) : (
              <div className="empty-pie-container">
                <p className="empty-content-placeholder">
                  No data available for the selected time range.
                </p>
              </div>
            )}
          </div>
          <Button
            className="firebase-btn"
            handleClick={() => {
              setLoading(true);
              redirect("https://console.firebase.google.com/");
            }}
          >
            Firebase
          </Button>
        </>
      )}
    </div>
  );
};

export default StatsData;
