import singleLayoutIco from "../assets/images/layout-type-1.svg";

// import staggeredLayoutIco from "../assets/images/layout-type-2.svg";

import alternatingLayoutIco from "../assets/images/layout-type-3.svg";

export default [
  {
    name: "single",
    img: singleLayoutIco,
    value: "single",
    imgClass: {}
  },
  // {
  //   name: "staggered",
  //   img: staggeredLayoutIco,
  //   value: "staggered",
  //   imgClass: {}
  // },
  {
    name: "alternating",
    img: alternatingLayoutIco,
    value: "alternating",
    imgClass: {}
  }
];
