/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import classnames from "classnames";
import _ from "lodash";
import { useParams, withRouter } from "react-router-dom";
import { useSelector, connect } from "react-redux";
import notifications from "../../../../utils/notifications";
import contentService from "../../../../services/content";
import accountService from "../../../../services/account";
import ArrowDownIcon from "../../../../assets/images/arrow-down.svg";
import PhotoContentInputs from "../../../../components/ContentsInput/PhotoInputs";
import PostContentInputs from "../../../../components/ContentsInput/PostInputs";
import MerchandiseInputs from "../../../../components/ContentsInput/MerchandiseInputs";
import ArticleInputs from "../../../../components/ContentsInput/ArticleInputs";
import NewsInputs from "../../../../components/ContentsInput/NewsInput";
import MusicInputs from "../../../../components/ContentsInput/MusicInputs";
import ContentPopup from "../../../../components/ContentPopup";
import mainActions from "../../../../redux/actions/main";
import "./index.scss";

const CreateEditContent = ({ history, setContentInProgress }) => {
  const [currentContentType, setContentType] = useState("photo");
  const [creators, setCreators] = useState([]);
  const [UplodingData, setUploadingData] = useState({
    success: false,
    inprogress: false
  });
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isDisabledShare, setDisabledShare] = useState(false);

  const { id } = useParams();
  const { contentid } = useParams();

  const username = useSelector(state => state.auth.currentUser.username);
  const draftApp = useSelector(state => state.application.draftApp);
  const { pages, content_module, tags } = draftApp;
  const [NextCreatorUrl, setNextCreatorUrl] = useState("");

  const getCreators = async () => {
    try {
      const { results, next } = await accountService.getApplicationUser({
        nextUrl: NextCreatorUrl,
        app_id: draftApp.id,
        role: "creator1,creator2,creator3"
      });
      await setCreators([...creators, ...results]);
      setNextCreatorUrl(next);
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };

  const updateContentKeys = appContentModule => {
    let updateContentType = {};

    if (appContentModule) {
      updateContentType = {
        likes_enabled: appContentModule.likes,
        is_premium: appContentModule.premium,
        comments_enabled: appContentModule.comment,
        shares_enabled: appContentModule.share,
        send_notification: appContentModule.notification,
        main_feed: true
      };
    }
    return updateContentType;
  };
  const [content, setContent] = useState({
    ...updateContentKeys(content_module.photo),
    app: id,
    type: "photo",
    username
  });
  const [contentShadow, setContentShadow] = useState({
    ...updateContentKeys(content_module.photo),
    app: id,
    type: "photo",
    username
  });

  const userId = useSelector(state => state.auth.currentUser.id);

  const contentsType = [
    "photo",
    "post",
    "video",
    "event",
    "music",
    "merchandise",
    "article",
    "news"
  ];

  const checkResponse = res => {
    if (res && res.status && (res.status === 200 || res.status === 201)) {
      setUploadingData({ success: true, inprogress: false });
      setIsOpenPopup(true);
      setContentInProgress(false);
    } else {
      setUploadingData({ success: false, inprogress: false });
      setIsOpenPopup(true);
    }
  };

  useEffect(() => {
    if (!content.main_feed && !content.page) {
      setDisabledShare(true);
    } else {
      setDisabledShare(false);
    }
  }, [content.main_feed, content.page]);

  const createContent = async () => {
    setUploadingData({ ...UplodingData, inprogress: true });
    content.user_id = userId;

    const res = await contentService.createContent(content);
    checkResponse(res);
  };
  const updateContent = async () => {
    setUploadingData({ ...UplodingData, inprogress: true });
    content.user_id = userId;
    const res = await contentService.updateContent(content);
    checkResponse(res);
  };
  const nlTagSelectOptions = [];
  const enTagSelectOptions = [];
  tags.forEach(tag => {
    nlTagSelectOptions.push({
      value: tag.tag,
      label: tag.tag
    });
    enTagSelectOptions.push({
      value: tag.tag_alt,
      label: tag.tag_alt
    });
  });
  if (content.tags) {
    content.tags.forEach(tag => {
      if (!nlTagSelectOptions.find(tagOption => tagOption.value === tag))
        nlTagSelectOptions.push({
          value: tag,
          label: tag
        });
      if (!enTagSelectOptions.find(tagOption => tagOption.value === tag))
        enTagSelectOptions.push({
          value: tag,
          label: tag
        });
    });
  }
  const creatorsSelectOptions = creators.map(creator => ({
    value: creator.id,
    label: creator.username
  }));
  const languageSelectOptions = [
    {
      value: "nl",
      label: "Dutch"
    },
    {
      value: "en",
      label: "English"
    }
  ];
  const tagSelectOptions = {
    nl: nlTagSelectOptions,
    en: enTagSelectOptions,
    general: nlTagSelectOptions
  };

  const pageEnabled = draftApp.content_page_enabled;
  const notificationEnabled = draftApp.content_notification_enabled;

  const getContentType = contentType => {
    switch (contentType) {
      case "photo":
        return (
          <PhotoContentInputs
            setContent={setContent}
            content={content}
            pages={pages}
            tags={tagSelectOptions}
            pageEnabled={pageEnabled}
            notificationEnabled={notificationEnabled}
          />
        );
      case "post":
        return (
          <PostContentInputs
            setContent={setContent}
            content={content}
            pages={pages}
            tags={tagSelectOptions}
            pageEnabled={pageEnabled}
            notificationEnabled={notificationEnabled}
          />
        );
      case "video":
        return (
          <PhotoContentInputs
            setContent={setContent}
            content={content}
            pages={pages}
            tags={tagSelectOptions}
            pageEnabled={pageEnabled}
            notificationEnabled={notificationEnabled}
            video
          />
        );
      case "event":
        return (
          <MerchandiseInputs
            setContent={setContent}
            content={content}
            pages={pages}
            tags={tagSelectOptions}
            pageEnabled={pageEnabled}
            notificationEnabled={notificationEnabled}
          />
        );
      case "music":
        return (
          <MusicInputs
            setContent={setContent}
            content={content}
            pages={pages}
            tags={tagSelectOptions}
            pageEnabled={pageEnabled}
            notificationEnabled={notificationEnabled}
          />
        );
      case "merchandise":
        return (
          <MerchandiseInputs
            setContent={setContent}
            content={content}
            pages={pages}
            tags={tagSelectOptions}
            pageEnabled={pageEnabled}
            notificationEnabled={notificationEnabled}
            creators={creatorsSelectOptions}
            languages={languageSelectOptions}
          />
        );
      case "article":
        return (
          <ArticleInputs
            setContent={setContent}
            content={content}
            pages={pages}
            pageEnabled={pageEnabled}
            notificationEnabled={notificationEnabled}
            tags={tagSelectOptions}
            creators={creatorsSelectOptions}
            languages={languageSelectOptions}
          />
        );
      case "news":
        return (
          <NewsInputs
            setContent={setContent}
            content={content}
            pages={pages}
            tags={tagSelectOptions}
            pageEnabled={pageEnabled}
            notificationEnabled={notificationEnabled}
            languages={languageSelectOptions}
          />
        );
      default:
        return "Uknown media type";
    }
  };
  const getContent = async () => {
    try {
      const res = await contentService.getContent(contentid);
      setContentShadow(res);
      setContent(res);
      setContentType(res.type);
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };
  useEffect(() => {
    if (contentid) {
      getContent();
    }
  }, []);

  useEffect(() => {
    getCreators();
  }, []);

  useEffect(() => {
    const { body } = document;
    if (isOpenPopup) {
      body.style.overflowY = "hidden";
    } else {
      body.style.overflowY = "unset";
    }
  }, [isOpenPopup]);

  useEffect(() => {
    if (_.isEqual(content, contentShadow)) {
      setContentInProgress(false);
    } else {
      setContentInProgress(true);
    }
  }, [content]);

  return (
    <div
      className={`create-content-container ${
        UplodingData.inprogress ? "off-clicking" : ""
      } `}
    >
      {isOpenPopup && (
        <ContentPopup
          onExit={() => setIsOpenPopup(false)}
          setIsOpenPopup={setIsOpenPopup}
          UplodingData={UplodingData}
          goback={() => history.goBack()}
          successText={
            currentContentType === "video"
              ? "Content is being processed and will be published soon!"
              : "Congratulation! Your content is published."
          }
        />
      )}
      <div className="create-content-header">
        <div
          className="return-button"
          onClick={() => {
            setContentType("photo");
            history.goBack();
            setContentInProgress(false);
          }}
        >
          <img src={ArrowDownIcon} alt="" />
          <p className="title">
            {contentid ? "Edit content" : "Create new content"}
          </p>
        </div>
        {!contentid && (
          <div>
            <p className="title">CONTENT CATEGORY</p>
            <div className="content-category-container">
              {contentsType.map(contentType => (
                <div
                  key={contentType}
                  onClick={() => {
                    setContentType(contentType);
                    setContent({
                      ...updateContentKeys(content_module[contentType]),
                      app: id,
                      type: contentType,
                      username
                    });
                  }}
                  className={classnames({
                    "content-category-item": true,
                    selected: currentContentType === contentType
                  })}
                >
                  <span className="item-title">{contentType}</span>
                </div>
              ))}
              <div className="placeholder" />
              <div className="placeholder" />
              <div className="placeholder" />
            </div>
          </div>
        )}
      </div>
      <div className="content-input-container">
        {getContentType(currentContentType)}
      </div>
      <div className="create-content-footer">
        <div
          className={classnames({
            "share-btn": true,
            disabled: isDisabledShare
          })}
          onClick={contentid ? updateContent : createContent}
        >
          <p>{contentid ? "SAVE CHANGES" : "SHARE"}</p>
          {UplodingData.inprogress && (
            <div className="lds-ring">
              <div />
              <div />
              <div />
              <div />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  setContentInProgress: isContentInProgress =>
    dispatch(mainActions.setContentInProgress(isContentInProgress))
});

export default withRouter(connect(null, mapDispatchToProps)(CreateEditContent));
