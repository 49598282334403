/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { SlideDown } from "react-slidedown";

import arrow from "../../../assets/images/arrow-down-white.svg";

import "react-slidedown/lib/slidedown.css";
import "./index.scss";

const roles = [
    "admin",
    "fan",
    "creator1",
    "creator2",
    "creator3"
]

const RolePicker = ({ currentRole, onRoleChange }) => {
  
    const [isOpen, setIsOpen] = useState(false);
    const [selectedRoleName, setSelectedRoleName] = useState(roles[0]);

    useEffect(() => {
        const index = roles.findIndex(role => role === currentRole);

        setSelectedRoleName(roles[index]);
    }, []);

    const handleClick = role => {
        setIsOpen(!isOpen);
        setSelectedRoleName(role);
        onRoleChange(role);
    };

    return (
        <div className={classnames({"role-picker": true, isOpen: isOpen })} onClick={() => setIsOpen(!isOpen)}>
            <div className="role-picker-header">
                <p className="title">
                    {selectedRoleName}
                </p>
                <img
                    className={classnames({ "arrow": true, "arrow-up": isOpen })}
                    src={arrow}
                    alt="arrow"
                />
            </div>
            <SlideDown closed={!isOpen} className="scrolling">
                <ul className="role-picker-content">
                    {roles.map(role => (
                        <li
                            key={role}
                            text={role}
                            onClick={() => { handleClick(role); }}
                        >
                            <p>{role}</p>
                        </li>
                    ))}
                </ul>
            </SlideDown>
        </div>
    );
};

export default RolePicker;
