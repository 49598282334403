export default [
  {
    dropdownTitle: "App information",
    dropdownKey: "app_information",
    dropdownItems: [
      { title: "Name", field: "name", form: "input" },
      {
        title: "Subtitle",
        field: "subtitle",
        tooltip: "1-30 characters",
        form: "input"
      },
      {
        title: "Privacy Policy URL",
        field: "privacy_policy_url",
        form: "input"
      },
      {
        title: "Promotional text",
        field: "promotional_text",
        form: "textarea",
        tooltip: "1-170 characters",
        height: 177
      },
      {
        title: "Description",
        field: "description",
        tooltip: "1-3940 characters",
        form: "textarea",
        height: 387
      },
      {
        title: "Keywords",
        field: "keywords",
        tooltip: "1-100 characters",
        form: "textarea",
        height: 127
      },
      {
        title: "Support URL",
        field: "support_url",
        form: "input"
      },
      {
        title: "Marketing URL (optional)",
        field: "marketing_url",
        form: "input"
      },
      {
        title: "Store link",
        tooltip: "Please fill in after publishing (developer task)",
        field: "store_link",
        form: "input"
      },
      {
        title: "Subscription shared secret",
        tooltip: "Please fill in after publishing (developer task)",
        field: "apple_subscription_shared_secret",
        form: "input"
      }
    ]
  },
  {
    dropdownTitle: "General information",
    dropdownKey: "general_information",
    dropdownItems: [
      { title: "Bundle ID", field: "bundle_id", form: "input" },
      { title: "SKU", field: "sku", form: "input" },
      { title: "Apple ID", field: "apple_id", form: "input" },
      { title: "Age rating", field: "age_rating", form: "input" },
      { title: "Primary language", field: "primary_language", form: "input" },
      { title: "Category", field: "category", form: "input" },
      {
        title: "Secondary category (optional)",
        field: "secondary_category",
        form: "input"
      },
      { title: "Copyright", field: "copyright", form: "input" }
    ]
  }
];
