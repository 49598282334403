import React from "react";
import { withRouter } from "react-router-dom";

import "./index.scss";

const Menu = ({
  history,
  tabs,
  location,
  setisopenpopup,
  iscontentinprogress,
  setredirectpath
}) => {
  const checkContentProgress = path => {
    if (iscontentinprogress) {
      setredirectpath(path);
      setisopenpopup(true);
    } else {
      history.push(path);
    }
  };
  return (
    <ul className="sidebar-menu">
      {tabs.map(tab => (
        <li
          key={tab.name}
          className={location.pathname.includes(tab.url) ? "active" : ""}
        >
          <span
            onClick={() => {
              checkContentProgress(tab.url);
            }}
            className={tab.name !== "STATISTIC" ? "" : "label off-clicking"}
          >
            <p className="label">{tab.name}</p>
            <div />
          </span>
        </li>
      ))}
    </ul>
  );
};

export default withRouter(Menu);
