/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";

import "./index.scss";

const CheckboxImg = props => {
  const { name, img, value, onChange, imgClass, checked } = props;

  return (
    <div className="radio-button">
      <input
        type="radio"
        name={name}
        id={name + value}
        className="input-hidden"
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <label htmlFor={name + value}>
        <div className="border">
          <img src={img} alt={value} className={imgClass} />
        </div>
      </label>
    </div>
  );
};

export default CheckboxImg;
