import React, { useEffect } from "react";
import { connect } from "react-redux";
import WebFont from "webfontloader";

import applicationActions from "../../../redux/actions/application";
import mainActions from "../../../redux/actions/main";
import ColorPicker from "../../../components/common/ColorPicker";
import FontPicker from "../../../components/common/FontPicker";
import InputImg from "../../../components/common/InputImg";
import CheckboxImg from "../../../components/common/CheckboxImg";
import checkboxsImgData from "../../../constants/LayoutConttentFeed";
import fonts from "../../../constants/FontsDropdown";
import {
  fontPickersData,
  colorPickersData
} from "../../../constants/setDesignData";

import "./index.scss";

const SetAppDesign = ({
  setQRCodePopup,
  updateDraftApp,
  draftApp,
  qrData,
  isCreatingProcess,
  updateQrData
}) => {
  useEffect(() => {
    if (isCreatingProcess) {
      const downloadingFonts = [];

      fonts.forEach(key => {
        downloadingFonts.push(key.name);
      });
      WebFont.load({
        google: {
          families: downloadingFonts
        }
      });
      setQRCodePopup(true);

      updateDraftApp({
        ...draftApp,
        fonts: {
          primary: "Roboto",
          secondary: "Roboto"
        },
        colors: {
          color_a: colorPickersData[0].value,
          color_b: colorPickersData[1].value,
          color_highlight: colorPickersData[2].value,
          color_text_icon: colorPickersData[3].value,
          color_overlay: colorPickersData[4].value,
          color_post: colorPickersData[5].value,
          color_call_action: colorPickersData[6].value
        }
      });
    }
  }, []);

  useEffect(() => {
    if (draftApp.layout === null) {
      updateDraftApp({
        ...draftApp,
        layout: "single"
      });
    }
  }, []);

  const inputsImgData = [
    { name: "Unliked icon", value: draftApp.icon_like, reduxName: "icon_like" },
    {
      name: "Liked icon",
      value: draftApp.icon_unlike,
      reduxName: "icon_unlike"
    },
    {
      name: "Comment icon",
      value: draftApp.icon_comment,
      reduxName: "icon_comment"
    },
    { name: "Menu icon", value: draftApp.icon_menu, reduxName: "icon_menu" },
    // {
    //   name: "Profile icon",
    //   value: draftApp.icon_profile,
    //   reduxName: "icon_profile"
    // },
    { name: "Share icon", value: draftApp.icon_share, reduxName: "icon_share" }
  ];

  return (
    <div className="design-container">
      <div>
        <h4 className="title">COLORS</h4>
        <div className="colors-picker-wrapper">
          {colorPickersData.map(colorPicker => {
            return (
              <ColorPicker
                key={colorPicker.name}
                name={colorPicker.name}
                defaultcolor={
                  draftApp.colors[colorPicker.reduxName]
                    ? draftApp.colors[colorPicker.reduxName]
                    : colorPicker.value
                }
                onChange={color => {
                  updateDraftApp({
                    ...draftApp,
                    colors: {
                      ...draftApp.colors,
                      [colorPicker.reduxName]: color
                    }
                  });
                  updateQrData({
                    ...qrData,
                    colors: {
                      ...qrData.colors,
                      [colorPicker.reduxName]: color
                    }
                  });
                }}
              />
            );
          })}
        </div>
      </div>
      <div>
        <h4 className="title">FONTS</h4>
        <div className="fonts-picker-wrapper">
          {fontPickersData.map(fontPicker => {
            return (
              <FontPicker
                key={fontPicker.name}
                name={fontPicker.name}
                defaultFont={
                  draftApp.fonts[fontPicker.reduxName]
                    ? draftApp.fonts[fontPicker.reduxName]
                    : fontPicker.fonts[0].name
                }
                fonts={fontPicker.fonts}
                onChange={font => {
                  updateDraftApp({
                    ...draftApp,
                    fonts: {
                      ...draftApp.fonts,
                      [fontPicker.reduxName]: font
                    }
                  });
                  updateQrData({
                    ...qrData,
                    fonts: {
                      ...qrData.fonts,
                      [fontPicker.reduxName]: font
                    }
                  });
                }}
              />
            );
          })}
        </div>
      </div>
      <div>
        <h4 className="title">ICONS</h4>
        <p className="input-description">
          You can change the default icons to your custom shapes here.
          <br />
          Files must meet the following requirements: 24-bit PNG with
          transparency, 80*80 px
        </p>
        <div className="input-image-wrapper">
          {inputsImgData.map(InputImgData => {
            return (
              <InputImg
                key={InputImgData.name}
                onChange={result =>
                  updateDraftApp({
                    ...draftApp,
                    [InputImgData.reduxName]: result
                  })
                }
                value={draftApp[InputImgData.reduxName]}
                name={InputImgData.reduxName}
                placeholder={InputImgData.name}
              />
            );
          })}
        </div>
      </div>
      {draftApp.content_feed_layout_enabled && (
        <div className="layer-wrapper">
          <h4 className="title">LAYOUT OF CONTENT FEED</h4>
          <div className="checkbox-wrapper">
            {checkboxsImgData.map(InputImgData => (
              <CheckboxImg
                key={InputImgData.name}
                name="layout"
                img={InputImgData.img}
                value={InputImgData.value}
                onChange={() => {
                  updateDraftApp({
                    ...draftApp,
                    layout: InputImgData.value
                  });
                  updateQrData({
                    ...qrData,
                    layout: InputImgData.value
                  });
                }}
                checked={draftApp.layout === InputImgData.name}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

SetAppDesign.propTypes = {};

const mapStateToProps = ({
  main: { isShowQRCodePopup },
  application: { draftApp, qrData, isCreatingProcess }
}) => ({
  isShowQRCodePopup,
  draftApp,
  qrData,
  isCreatingProcess
});

const mapDispatchToProps = dispatch => ({
  updateDraftApp: res => dispatch(applicationActions.updateDraftApp(res)),
  setQRCodePopup: res => dispatch(mainActions.setQRCodePopup(res)),
  updateQrData: data => dispatch(applicationActions.updateQrData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SetAppDesign);
