import React from "react";
import moment from "moment";
import { ResponsiveLine } from "@nivo/line";

const theme = {
  axis: {
    ticks: {
      text: {
        fontSize: 14,
        fill: "#ffffff"
      }
    },
    legend: {
      text: {
        fontSize: 15,
        fill: "#c5a486"
      }
    }
  },
  legends: {
    text: {
      fontSize: 20
    }
  }
};

const Line = ({ data, pointSize, tickValues, isShowDate }) => {
  const tooltip = ({ point }) => {
    return (
      <div className="tooltip-container">
        {isShowDate && (
          <div className="tooltip-content">{`Date: ${moment(
            point.data.x
          ).format("DD-MM-YYYY")}`}</div>
        )}
        <div className="tooltip-content">{`${point.serieId}: ${point.data.y}`}</div>
      </div>
    );
  };

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 200, bottom: 150, left: 100 }}
      curve="linear"
      theme={theme}
      gridXValues={[1]}
      gridYValues={[0]}
      xScale={{
        type: "time",
        format: "%Y-%m-%d",
        precision: "day",
        useUTC: false
      }}
      xFormat="time:%Y-%m-%d"
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: false,
        reverse: false
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 0,
        legend: "time",
        format: "%d-%m-%Y",
        tickValues,
        tickRotation: -72,
        tickPadding: 25,
        legendOffset: 120,
        legendPosition: "middle"
      }}
      axisLeft={{
        orient: "left",
        tickSize: 0,
        legend: "number of users",
        legendOffset: -55,
        legendPosition: "middle"
      }}
      colors={{ scheme: "yellow_orange_brown" }}
      pointSize={pointSize}
      pointColor={{ from: "color", modifiers: [] }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabel="y"
      pointLabelYOffset={-12}
      useMesh
      tooltip={tooltip}
      legends={[
        {
          anchor: "right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 40,
          itemsSpacing: 10,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          itemTextColor: "#fff",
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
    />
  );
};

export default Line;
