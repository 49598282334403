/* eslint-disable import/no-named-as-default-member */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import QRCode from "qrcode.react";

import mainActions from "../../redux/actions/main";
import Button from "../common/Button";

import "./index.scss";

const NotificationPopup = ({
  setNotificationPopup,
  setBlur,
  qrData,
  draftApp,
  notificationText,
  buttonText = "OKAY",
  isQRCodePopup = false
}) => {
  useEffect(() => {
    setBlur(true);
  });
  const handleClick = () => {
    setBlur(false);
    setNotificationPopup(false);
  };

  return (
    <div className="notification-popup-container">
      <div className="popup-content">
        {isQRCodePopup && (
          <div className="border">
            <div className="qr-code-popup">
              <QRCode
                value={JSON.stringify({
                  id: draftApp.id,
                  ...qrData
                })}
                size={100}
              />
            </div>
          </div>
        )}
        <p className="title">{notificationText}</p>
        <Button className="popup-btn" handleClick={handleClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
const mapStateToProps = ({
  main: { isBlur },
  application: { draftApp, qrData }
}) => ({
  isBlur,
  draftApp,
  qrData
});

const mapDispatchToProps = dispatch => ({
  setBlur: isBlur => dispatch(mainActions.setBlur(isBlur))
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPopup);
