import React from "react";
import { func } from "prop-types";
import classnames from "classnames";
import deleteIcon from "../../../assets/images/close-icon.svg";

import "./index.scss";

const TagButton = ({ children, className, handleClick, disabled, editable }) => {
  return (
    <button
      type="button"
      className={classnames({ button: true, [className]: true})}
      onClick={editable ? handleClick : null}
      disabled={disabled}
    >
      {children}
      {editable && <img
                    className={"delete-icon"}
                    src={deleteIcon}
                    alt="delete"
                />}
    </button>
  );
};

TagButton.propTypes = {
  handleClick: func
};

TagButton.defaultProps = {
  handleClick: null
};

export default TagButton;
