import React from "react";
import QRCode from "qrcode.react";

import "./index.scss";

const QRCodeImg = ({ value, size }) => {
  return (
    <div className="qr-code-container">
      <QRCode value={value} size={parseInt(size, 10)} />
    </div>
  );
};

export default QRCodeImg;
