import axios from "axios";

const getUsers = async ({ appId, nextUrl, searchValue }) => {
  if (searchValue && searchValue !== "") {
    const { data } = await axios.get(
      `/users/?app=${appId}&search=${searchValue}&ordering=email`
    );
    return data;
  }
  if (nextUrl && nextUrl !== "") {
    const { data } = await axios({
      baseURL: nextUrl,
      method: "get"
    });
    return data;
  }
  const { data } = await axios.get(
    `/users/?app=${appId}&role=admin,creator1,creator2,creator3&ordering=email`
  );

  return data;
};

const updateUser = async ({ userId, appId, updatedRole, updatedTags }) => {
  const path = `users/${userId}/?app=${appId}`;
  const body = {};

  if (updatedRole) {
    body.app_id = appId;
    body.role = updatedRole;
  }
  if (updatedTags) {
    body.tags = updatedTags;
  }

  const { data } = await axios.patch(path, body);
  return data;
};

export { getUsers, updateUser };

export default {
  getUsers,
  updateUser
};
