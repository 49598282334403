/* eslint-disable react/no-unescaped-entities */
/* eslint-disable radix */

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";
import InfiniteScroll from "react-infinite-scroll-component";

import Button from "../../../components/common/Button";
import Input from "../../../components/common/Input";
import UsersListItem from "../../../components/UsersList/UsersListItem";

import notifications from "../../../utils/notifications";
import userService from "../../../services/users";

import "react-calendar/dist/Calendar.css";
import "./index.scss";


const Users = () => {

  const [users, setUsers] = useState([]);
  const [nextUsersUrl, setNextUsersUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  
  const { id } = useParams();

  
  const getUsers = async (searchValue) => {
    try { 
      if (searchValue && searchValue !== "") {
        const res = await userService.getUsers({appId: id, searchValue: searchValue});
        setUsers(res.results);
        setNextUsersUrl(res.next);
        await setTimeout(() => setLoading(false), 4000);
      } else {
        const res = await userService.getUsers({appId: id});
        setUsers(res.results);
        setNextUsersUrl(res.next);
        setLoading(false);
      }
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };
  useEffect(() => {
    getUsers()
  }, []);

  const loadMoreUsers = async () => {
    if (nextUsersUrl !== "" && nextUsersUrl !== null) {
      try {
          const resUsers = await userService.getUsers({
              nextUrl: nextUsersUrl
          });
          await setUsers([...users, ...resUsers.results]);
          setNextUsersUrl(resUsers.next);
      } catch (err) {
          notifications.error({
              msg:
                  err.response && err.response.data
                  ? Object.values(err.response.data).join(", ")
                  : err.message
          });
      }
    }
  };

  return (
    <div className="users-container">
      <div className="list-container">
        <p className="list-title">USERS</p>
        <div className="search-container">
          <Input
            onChange={event => setSearchInput(event.target.value)}
            value={searchInput}
          />
          <Button handleClick={() => getUsers(searchInput)} className="search-button">
            Search
          </Button>
        </div>
        {loading ? 
          <>
            <BarLoader
              size={150}
              color="rgba(241, 209, 158, 0.7)"
              loading={loading}
            />
          </>
          :
          <div className="contents">
            <InfiniteScroll
              dataLength={users.length}
              next={loadMoreUsers}
              height="80vh"
              hasMore={loadMoreUsers !== null}
            >
              {users && users.length > 0 ?
                users.map(user => (
                  <UsersListItem
                    key={user.id}
                    user={user}
                    app_id={id}
                  />
                ))
                :
                <p className="no-users">No users</p>
              }
            </InfiniteScroll>
          </div>
        }
      </div>
    </div>
  );
};

export default Users;
