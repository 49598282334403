/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
import axios from "axios";
import { objectToFormData } from "object-to-formdata";
import notifications from "../utils/notifications";

const getApplicationContents = async queries => {
  if (queries.nextUrl && queries.nextUrl !== "") {
    const { data } = await axios({
      baseURL: queries.nextUrl,
      method: "get"
    });
    return data;
  }
  const defaultQueries = {
    app: "",
    order: "",
    main_feed: "Photo",
    offset: 0,
    limit: 10
  };

  if (queries.type && queries.type !== "all") {
    defaultQueries.type = queries.type;
  }

  let queriesString = "?";

  for (let [key, query] of Object.entries(defaultQueries)) {
    if (queries.hasOwnProperty(key)) {
      query = queries[key];
    }
    if (query !== "") {
      queriesString += `${key}=${query}&`;
    }
  }

  queriesString = queriesString.slice(0, -1);

  const { data } = await axios.get(`/contents/${queriesString}`);
  return data;
};

const removeContent = async id => {
  const { data } = await axios.delete(`/contents/${id}/`);

  return data;
};

const sendDataToBambuser = async (data, fileData) => {
  let fileRes = null;

  try {
    const { data: uploadTicketsRes } = await axios({
      url: "/contents/bambuser-ttl/",
      method: "post",
      data: {
        type: data.type === "video" ? "video" : "image",
        filename: fileData.name,
        custom_data: btoa(unescape(encodeURIComponent(JSON.stringify(data))))
      }
    });
    fileRes = await axios({
      baseURL: uploadTicketsRes.upload_url,
      method: "post",
      data: objectToFormData({ file: fileData }),
      headers: { "Content-Type": "multipart/form-data" }
    });
  } catch (err) {
    notifications.error({
      msg:
        err.response && err.response.data
          ? Object.values(err.response.data).join(", ")
          : err.message
    });
  }
  return fileRes;
};

const updateContent = async content => {
  let data = { ...content };
  let fileData = null;
  let res = null;

  if (
    data.hasOwnProperty("link") &&
    data.link !== null &&
    typeof data.link === "object"
  ) {
    fileData = data.link;
    delete data.link;
    return sendDataToBambuser(data, fileData);
  }

  try {
    res = await axios.put(`/contents/${data.id}/`, data);
  } catch (err) {
    notifications.error({
      msg:
        err.response && err.response.data
          ? Object.values(err.response.data).join(", ")
          : err.message
    });
  }

  return res;
};

const getContent = async id => {
  const { data } = await axios.get(`/contents/${id}/`);

  return data;
};

const createContent = async content => {
  let data = { ...content };
  let fileData = null;
  if (
    data.hasOwnProperty("link") &&
    data.link !== null &&
    typeof data.link === "object"
  ) {
    fileData = data.link;
    delete data.link;
    return sendDataToBambuser(data, fileData);
  }

  let res = null;
  try {
    res = await axios.post("/contents/", data);
  } catch (err) {
    notifications.error({
      msg:
        err.response && err.response.data
          ? Object.values(err.response.data).join(", ")
          : err.message
    });
  }
  return res;
};

export {
  getApplicationContents,
  updateContent,
  removeContent,
  createContent,
  getContent
};

export default {
  getApplicationContents,
  updateContent,
  removeContent,
  createContent,
  getContent
};
