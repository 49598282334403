/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from "react";
import { SlideDown } from "react-slidedown";

import Input from "../../../components/common/Input";
import Textarea from "../../../components/common/Textarea";
import ArrowDown from "../../../assets/images/arrow-down.svg";

import "./index.scss";

const Dropdown = ({ dropdown, publishInfo, updatePublishInfo }) => {
  const [isDropdownOpen, setOpenDropdown] = useState(false);

  const handleChange = (event, item) => {
    updatePublishInfo({
      ...publishInfo,
      [dropdown.dropdownKey]: {
        ...publishInfo[dropdown.dropdownKey],
        [item.field]: event.target.value
      }
    });
  };

  const inputForm = item => {
    return (
      <Input
        type="text"
        onChange={event => {
          handleChange(event, item);
        }}
        value={
          publishInfo[dropdown.dropdownKey][item.field]
            ? publishInfo[dropdown.dropdownKey][item.field]
            : ""
        }
      />
    );
  };

  const textareaForm = item => {
    return (
      <Textarea
        styles={{ height: item.height }}
        onChange={event => {
          handleChange(event, item);
        }}
        value={
          publishInfo[dropdown.dropdownKey][item.field]
            ? publishInfo[dropdown.dropdownKey][item.field]
            : ""
        }
      />
    );
  };

  const formComponent = item => {
    if (item.form === "textarea") {
      return textareaForm(item);
    }
    return inputForm(item);
  };

  return (
    <div className="custom-dropdown">
      <div
        className="custom-dropdown-header"
        onClick={() => setOpenDropdown(!isDropdownOpen)}
      >
        <p>{dropdown.dropdownTitle}</p>
        <img
          className={isDropdownOpen ? "arrow-up" : ""}
          src={ArrowDown}
          alt=""
        />
      </div>
      <SlideDown closed={!isDropdownOpen}>
        <div className="custom-dropdown-content">
          {dropdown.dropdownItems.map(item => (
            <div key={item.title} className="dropdown-form-control">
              <p>{item.title}:</p>
              {item.tooltip && <p className="tooltip">{item.tooltip}</p>}
              {formComponent(item)}
            </div>
          ))}
        </div>
      </SlideDown>
    </div>
  );
};

export default Dropdown;
